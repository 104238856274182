import "./ScheduleOptions.scss";

import { IonButton, IonContent, IonIcon, IonLabel } from "@ionic/react";
import { useStateMachine } from "little-state-machine";
import { useHistory } from "react-router";
import { RoutePath } from "../../model/RoutePath.enum";
import { updateSteps } from "../../state/updateState";
import { MainFooter, MainHeader } from "../Layout/Layout";
import { useTranslation } from "react-i18next";
import React from "react";

const ScheduleOptions: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { actions } = useStateMachine({ updateSteps });

  const handleOnGetHelpNow = () => {
    actions.updateSteps({
      schedule: { startTimeRange: "", date: "", endTimeRange: "", time: "" },
    });
    history.push(`${RoutePath.REQUEST}/${RoutePath.SUMMARY}`);
  };

  return (
    <IonContent>
      <MainHeader
        title={t(
          "Would you like to be helped immediately or book an appointment at a later date?"
        )}
        image={`${process.env.PUBLIC_URL}/assets/images/${
          process.env.REACT_APP_ALT_ICONS_PATH || ""
        }Clock.svg`}
      >
        <section>
          <div className="button-grid">
            <IonButton
              id="so-get-help-now"
              className="button-tcs"
              color="secondary"
              expand="block"
              type="button"
              onClick={handleOnGetHelpNow}
            >
              {t("Get help now")}
            </IonButton>
            <IonButton
              id="so-book-appointment"
              className="button-tcs"
              color="secondary"
              expand="block"
              type="button"
              onClick={() =>
                history.push(
                  `${RoutePath.REQUEST}/${RoutePath.SCHEDULE_SERVICE}`
                )
              }
            >
              {t("Book an appointment")}
            </IonButton>
          </div>
        </section>
      </MainHeader>
      <MainFooter>
        <IonButton
          id="so-back"
          className="button-tcs outline full-width back"
          expand="block"
          onClick={() => history.back()}
        >
          <div className="wrapper">
            <IonIcon
              size="large"
              src={`${process.env.PUBLIC_URL}/assets/images/chevron-back.svg`}
            />
            <IonLabel>{t("Back")}</IonLabel>
            <div />
          </div>
        </IonButton>
      </MainFooter>
    </IonContent>
  );
};

export default ScheduleOptions;
