import "./HighwayImmobilized.scss";

import { IonButton, IonContent, IonLabel } from "@ionic/react";
import { useStateMachine } from "little-state-machine";
import { useHistory } from "react-router";

import { RoutePath } from "../../model/RoutePath.enum";
import {
  updateGlobalState,
  updateSteps,
  updateSummary,
} from "../../state/updateState";
import { MainFooter, MainHeader } from "../Layout/Layout";
import { useTranslation } from "react-i18next";

import React from "react";
import ConsentManagement from "../ConsentManagement/ConsentManagement";
import useUserCentrics from "../../userCentrics/userCentrics";

const HighwayImmobilized: React.FC = () => {
  const { t } = useTranslation();
  const { actions, state } = useStateMachine({
    updateGlobalState,
    updateSteps,
    updateSummary,
  });

  const history = useHistory();

  const isUserCentricsReady = useUserCentrics();

  const goToNextRoute = (route: string, immobilizedOnHighway: boolean) => {
    if (state.isInEditMode) {
      actions.updateSummary({ immobilizedOnHighway });
      // reset appointment (should not be able to make an appointment on the highway
      if (
        state.steps.immobilizedOnHighway &&
        state.steps.schedule.date &&
        state.steps.schedule.startTimeRange &&
        state.steps.schedule.endTimeRange &&
        state.steps.schedule.time
      ) {
        actions.updateSteps({
          schedule: {
            date: "",
            startTimeRange: "",
            endTimeRange: "",
            time: "",
          },
        });
      }
      if (immobilizedOnHighway) {
        history.push(`${RoutePath.REQUEST}/${RoutePath.IMMOBILIZED_LANE}`);
      } else {
        history.push(`${RoutePath.REQUEST}/${RoutePath.LOCATE_ME_NOW}`);
      }
    } else {
      actions.updateSteps({ immobilizedOnHighway });
      history.push(`${RoutePath.REQUEST}/${route}`);
    }
  };

  return (
    <IonContent>
      <MainHeader
        title={t("Is your car immobilized on a highway?")}
        image={`${process.env.PUBLIC_URL}/assets/images/${
          process.env.REACT_APP_ALT_ICONS_PATH || ""
        }highway.svg`}
      >
        <section>
          <div className="button-grid">
            <IonButton
              id="immobilized-button"
              className="button-tcs"
              color="secondary"
              expand="block"
              type="button"
              onClick={() => goToNextRoute(RoutePath.IMMOBILIZED_LANE, true)}
            >
              {t("Yes")}
            </IonButton>
            <IonButton
              id="immobilized-btn-no"
              className="button-tcs"
              color="secondary"
              expand="block"
              type="button"
              onClick={() => goToNextRoute(RoutePath.INCIDENT_TYPE, false)}
            >
              {t("No")}
            </IonButton>
          </div>
        </section>
        {process.env.REACT_APP_DISPLAY_CONSENT_MANAGEMENT &&
          process.env.REACT_APP_DISPLAY_CONSENT_MANAGEMENT == "true" &&
          state.isFromIVR && <ConsentManagement />}
        {/* {isUserCentricsReady && (
          <button
            className="privacy-settings-link"
            onClick={() => window.UC_UI.showSecondLayer()}
          >
            Privacy Settings
          </button>
        )} */}
      </MainHeader>
      <MainFooter>
        {state.isInEditMode && (
          <IonButton
            id="immobilized-btn-back"
            className="button-tcs"
            expand="block"
            onClick={() => history.back()}
          >
            <IonLabel>{t("Cancel")}</IonLabel>
          </IonButton>
        )}
      </MainFooter>
    </IonContent>
  );
};

export default HighwayImmobilized;
