import "./MainContainer.scss";

import { RoutePath } from "../../model/RoutePath.enum";
import HighwayImmobilized from "../HighwayImmobilized/HighwayImmobilized";
import ImmobilizedLane from "../ImmobilizedLane/ImmobilizedLane";
import OtherIncident from "../Incident/OtherIncident/OtherIncident";
import GeoLocation from "../Location/GeoLocation/GeoLocation";
import LocationTutorial from "../Location/LocationTutorial/LocationTutorial";
import ParkType from "../ParkType/ParkType";
import PersonalDetails from "../PersonalDetails/PersonalDetails";
import Summary from "../Summary/Summary";
import ThankYou from "../ThankYou/ThankYou";
import VehicleDetails from "../VehicleDetails/VehicleDetails";
import StarterIncident from "../Incident/StarterIncident/StarterIncident";
import FuelIncident from "../Incident/FuelIncident/FuelIncident";
import DoorsAndKeysIncident from "../Incident/DoorsAndKeysIncident/DoorsAndKeysIncident";
import LaneWarning from "../LaneWarning/LaneWarning";
import EngineIncident from "../Incident/EngineIncident/EngineIncident";
import FluentLossIncident from "../Incident/FluidLoss/FluidLossIncident";
import BicycleIncident from "../Incident/BicycleIncident/BicycleIncident";
import WheelsAndTiresIncident from "../Incident/WheelsAndTiresIncident/WheelsAndTiresIncident";
import ScheduleOptions from "../Schedule/ScheduleOptions";
import ScheduleService from "../Schedule/ScheduleService";
import ValidateAuthentication from "../OtpAuthentication/ValidateAuthentication/ValidateAuthentication";
import React from "react";
import { Route, useRouteMatch } from "react-router-dom";
import RequestAuthentication from "../OtpAuthentication/RequestAuthentication/RequestAuthentication";
import ProtectedRoute from "../shared/ProtectedRoute/protected-route";
import IncidentPicker from "../Incident/IncidentPicker";
import Confirmation from "../Confirmation/Confirmation";
import { IonRouterOutlet } from "@ionic/react";
import LocateMeNow from "../Location/LocateMeNow/LocateMeNow";
import CaseAlreadySubmitted from "../CaseAlreadySubmitted/CaseAlreadySubmitted";
import AbroadAdditionalInfos from "../AbroadAdditionalInfos/AbroadAdditionalInfos";
import InsuranceProvider from "../InsuranceProvider/InsuranceProvider";
import WheelsAndTiresIncidentInsurance from "../Incident/WheelsAndTiresIncident/WheelsAndTiresIncidentInsurance";
import InsuranceProviderInfo from "../InsuranceProvider/InsuranceProviderInfo";

const MainContainer: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <div className="container">
      <IonRouterOutlet>
        <Route
          path={`${path}/${RoutePath.REQUEST_AUTHENTICATION}`}
          exact={true}
        >
          <RequestAuthentication />
        </Route>
        <Route
          path={`${path}/${RoutePath.VALIDATE_AUTHENTICATION}`}
          exact={true}
        >
          <ValidateAuthentication />
        </Route>
        <ProtectedRoute
          path={`${path}/${RoutePath.VEHICLE_DETAILS}`}
          exact={true}
        >
          <VehicleDetails />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${path}/${RoutePath.HIGHWAY_IMMOBILIZED}`}
          exact={true}
        >
          <HighwayImmobilized />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${path}/${RoutePath.IMMOBILIZED_LANE}`}
          exact={true}
        >
          <ImmobilizedLane />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${path}/${RoutePath.ACTIVE_LANE_WARNING}`}
          exact={true}
        >
          <LaneWarning />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${path}/${RoutePath.EMERGENCY_LANE_WARNING}`}
          exact={true}
        >
          <LaneWarning />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${path}/${RoutePath.INCIDENT_TYPE}`}
          exact={true}
        >
          <IncidentPicker />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${path}/${RoutePath.IMMOBILIZED_LANE}`}
          exact={true}
        >
          <ImmobilizedLane />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${path}/${RoutePath.EMERGENCY_LANE_WARNING}`}
          exact={true}
        >
          <LaneWarning />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${path}/${RoutePath.STARTER_ISSUE_INCIDENT}`}
          exact={true}
        >
          <StarterIncident />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${path}/${RoutePath.ENGINE_INCIDENT}`}
          exact={true}
        >
          <EngineIncident />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${path}/${RoutePath.WHEELS_AND_TIRES}`}
          exact={true}
        >
          <WheelsAndTiresIncident />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${path}/${RoutePath.DOORS_AND_KEYS_INCIDENT}`}
          exact={true}
        >
          <DoorsAndKeysIncident />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${path}/${RoutePath.FUEL_INCIDENT}`}
          exact={true}
        >
          <FuelIncident />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${path}/${RoutePath.FLUID_LOSS_INCIDENT}`}
          exact={true}
        >
          <FluentLossIncident />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${path}/${RoutePath.OTHER_INCIDENT}`}
          exact={true}
        >
          <OtherIncident />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${path}/${RoutePath.BICYCLE_INCIDENT}`}
          exact={true}
        >
          <BicycleIncident />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${path}/${RoutePath.WHEELS_AND_TIRES_INSURANCE}`}
          exact={true}
        >
          <WheelsAndTiresIncidentInsurance />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${path}/${RoutePath.INSURANCE_PROVIDER}`}
          exact={true}
        >
          <InsuranceProvider />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${path}/${RoutePath.INSURANCE_PROVIDER_INFO}`}
          exact={true}
        >
          <InsuranceProviderInfo />
        </ProtectedRoute>
        <ProtectedRoute path={`${path}/${RoutePath.PARKING_TYPE}`} exact={true}>
          <ParkType />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${path}/${RoutePath.SCHEDULE_OPTIONS}`}
          exact={true}
        >
          <ScheduleOptions />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${path}/${RoutePath.SCHEDULE_SERVICE}`}
          exact={true}
        >
          <ScheduleService />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${path}/${RoutePath.ABROAD_ADDITIONAL_INFOS}`}
          exact={true}
        >
          <AbroadAdditionalInfos />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${path}/${RoutePath.LOCATE_ME_NOW}`}
          exact={true}
        >
          <LocateMeNow />
        </ProtectedRoute>
        <Route
          path={`${path}/${RoutePath.CASE_ALREADY_SUBMITTED}`}
          exact={true}
        >
          <CaseAlreadySubmitted />
        </Route>
        <ProtectedRoute
          path={`${path}/${RoutePath.LOCATION_TUTORIAL}`}
          exact={true}
        >
          <LocationTutorial />
        </ProtectedRoute>
        <ProtectedRoute path={`${path}/${RoutePath.LOCATION}`} exact={true}>
          <GeoLocation />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${path}/${RoutePath.PERSONAL_DETAILS}`}
          exact={true}
        >
          <PersonalDetails />
        </ProtectedRoute>
        <ProtectedRoute path={`${path}/${RoutePath.SUMMARY}`} exact={true}>
          <Summary />
        </ProtectedRoute>
        <ProtectedRoute path={`${path}/${RoutePath.THANK_YOU}`} exact={true}>
          <ThankYou />
        </ProtectedRoute>
        <ProtectedRoute path={`${path}/${RoutePath.CONFIRMATION}`} exact={true}>
          <Confirmation />
        </ProtectedRoute>
      </IonRouterOutlet>
    </div>
  );
};

export default MainContainer;
