import { WheelAndTireDetails } from "../../model/Incident/WheelAndTireDetails";
import { Case } from "../../api/models/Case";
import { IncidentUtils } from "../IncidentUtils";
import { Appointment } from "../../model/Appointment";
import { AppointmentUtils } from "../AppointmentUtils";
import { PersonDetail } from "../../model/PersonDetail";
import { PersonalDetailsUtils } from "../PersonalDetailsUtils";
import { ImmobilizationType } from "../../model/ImmobilizationType.enum";
import { HighwayUtils } from "../HighwayUtils";
import { LocationUtils } from "../LocationUtils";

export class WheelAndTireUtils {
  public static serialize(
    data: WheelAndTireDetails,
    appointment: Appointment | undefined,
    personalDetail: PersonDetail,
    immobilizationType: ImmobilizationType,
    immobilizedOnHighway: boolean | undefined
  ): Partial<Case> {
    const isAutoCoverageCheck =
      process.env.REACT_APP_USE_AUTO_COVERAGE_CHECK === "true";
    return {
      MainCause: "12",
      Comment: this.getCaseComment(
        data,
        personalDetail,
        immobilizedOnHighway,
        immobilizationType
      ),
      Priority: this.getPriority(immobilizationType, data, appointment),
      ItemsUsedList: [
        {
          ...IncidentUtils.getItemType(),
          LastVehicleStatus: {
            PrimaryFault: this.getPrimaryFault(data),
            Comment: this.getTCSDescription(data),
            ...(isAutoCoverageCheck
              ? { AffectedWheelCount: this.getAffectedWheelCount(data) }
              : {}),
          },
        },
      ],
    };
  }

  public static getSummary(data: WheelAndTireDetails): string {
    return data.numberOfWheelsDamaged === "One" ? "Eines" : "Zwei oder mehr";
  }

  private static getPrimaryFault(data: WheelAndTireDetails): string {
    return data.tireStatus === "Tire pressure indicator"
      ? "65090"
      : data.tireStatus === "Other"
      ? "65076"
      : data.hasSpareWheel
      ? "65049"
      : "65050";
  }

  private static getTCSDescription(data: WheelAndTireDetails): string {
    return [
      this.incidentCode(data.tireStatus),
      data.numberOfWheelsDamaged === "One"
        ? "Ein Rad betroffen"
        : "Mehrere Räder betroffen",
      this.hasSpareWheelCode(data.hasSpareWheel),
      IncidentUtils.formatAdditionalInformation(data.additionalInformation),
    ]
      .filter(Boolean)
      .join("; ");
  }

  public static getAffectedWheelCount(data: WheelAndTireDetails): number {
    return data.numberOfWheelsDamaged === "One" ? 1 : 2;
  }

  private static hasSpareWheelCode(hasSpareWheel: boolean): string {
    return hasSpareWheel ? "Ersatzrad vorhanden" : "Kein Ersatzrad vorhanden";
  }

  private static incidentCode(tireStatus: string): string {
    return tireStatus === "Tire pressure indicator"
      ? "Reifendruckanzeige"
      : tireStatus === "Visible tire deflation"
      ? "Sichtbarer Luftverlust"
      : "Anderes Rad- oder Reifenproblem";
  }

  private static getCaseComment(
    data: WheelAndTireDetails,
    personalDetail: PersonDetail,
    immobilizedOnHighway: boolean | undefined,
    immobilizationType: ImmobilizationType
  ): string | undefined {
    const comments: string[] = [];

    if (data.tireStatus === "Tire pressure indicator") {
      comments.push("HOP Reifendruckanzeige; Kontrollampe/Displayinfo");
    }

    if (data.tireStatus === "Other") {
      comments.push("Callback anderer Grund Rad-Problem");
    }

    return [
      LocationUtils.getCaseComment(immobilizedOnHighway, immobilizationType),
      ...comments,
      PersonalDetailsUtils.serializeCaseComment(personalDetail),
      ...PersonalDetailsUtils.serializeCoverageComment(personalDetail),
    ]
      .filter(Boolean)
      .join("; ");
  }

  private static getPriority(
    immobilizationType: ImmobilizationType,
    data: WheelAndTireDetails,
    appointment: Appointment | undefined
  ): number {
    const appointmentPriority = AppointmentUtils.getPriority(appointment);
    let basePriority: number | undefined;

    switch (data.tireStatus) {
      case "Visible tire deflation":
      case "Tire pressure indicator":
      case "Other":
        basePriority = appointmentPriority || 3;
        break;
      default:
        basePriority = appointmentPriority;
    }

    return HighwayUtils.serializePriority(immobilizationType, basePriority);
  }
}
