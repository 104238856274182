import {
  FuelDetails,
  FuelIncidentType,
  FuelTypeOptions,
} from "../../model/Incident/FuelDetails";
import { IncidentUtils } from "../IncidentUtils";
import { Case } from "../../api/models/Case";
import { Appointment } from "../../model/Appointment";
import { AppointmentUtils } from "../AppointmentUtils";
import { PersonDetail } from "../../model/PersonDetail";
import { PersonalDetailsUtils } from "../PersonalDetailsUtils";
import { ImmobilizationType } from "../../model/ImmobilizationType.enum";
import { HighwayUtils } from "../HighwayUtils";
import { LocationUtils } from "../LocationUtils";

export class FuelUtils {
  public static serialize(
    data: FuelDetails,
    appointment: Appointment | undefined,
    personalDetails: PersonDetail,
    immobilizationType: ImmobilizationType,
    immobilizedOnHighway: boolean | undefined
  ): Partial<Case> {
    return {
      MainCause: "12",
      Comment: this.getCaseComment(
        data,
        personalDetails,
        immobilizedOnHighway,
        immobilizationType
      ),
      Priority: this.getPriority(immobilizationType, data, appointment),
      ItemsUsedList: [
        {
          ...IncidentUtils.getItemType(),
          LastVehicleStatus: {
            PrimaryFault: this.getPrimaryFault(data.breakdownType),
            Comment: this.getTCSDescription(data),
          },
        },
      ],
    };
  }
  public static getSummary(data: FuelDetails): string {
    return data.breakdownType;
  }

  private static getPrimaryFault(breakdownType: string): string {
    switch (breakdownType) {
      case FuelIncidentType.INCORRECTLY_REFUELED:
        return "65060";
      case FuelIncidentType.EMPTY_TANK:
        return "65061";
      case FuelIncidentType.OTHER:
        return "65076";
      case FuelIncidentType.FUEL_LOSS:
        return "65087";
      case FuelIncidentType.FUEL_TANK_EV_CONNECTOR:
        return "65059";
      /*case FuelIncidentType.FUEL_FILLER_BLOCKED:
        return "65076";*/
      default:
        // should not happen
        return "";
    }
  }

  private static getTCSDescription(data: FuelDetails): string {
    const comment =
      data.additionalInformation && data.additionalInformation !== ""
        ? IncidentUtils.formatAdditionalInformation(data.additionalInformation)
        : undefined;

    return [
      this.getCodeOfVehicleDescriptionByBreakdown(data),
      this.getCodeOfVehicleDescriptionByFuelType(data),
      comment,
    ]
      .filter(Boolean)
      .join("; ");
  }

  private static getCodeOfVehicleDescriptionByBreakdown(
    data: FuelDetails
  ): string | undefined {
    switch (data.breakdownType) {
      case FuelIncidentType.INCORRECTLY_REFUELED:
        return `Falsch getankt: ${data.wrongFuelLiters} Liters`;
      case FuelIncidentType.EMPTY_TANK:
        return "Tank leer";
      case FuelIncidentType.FUEL_LOSS:
        return "Treibstoffverlust";
      case FuelIncidentType.OTHER:
        return "";
      default:
        return undefined;
    }
  }

  private static getCodeOfVehicleDescriptionByFuelType(
    data: FuelDetails
  ): string | undefined {
    const isIncorreclyRefueled =
      data.breakdownType === FuelIncidentType.INCORRECTLY_REFUELED;
    const isFuelTankEvConnector =
      data.breakdownType === FuelIncidentType.FUEL_TANK_EV_CONNECTOR;

    switch (data.fuelType) {
      case FuelTypeOptions.OTHER:
        return isFuelTankEvConnector
          ? "Callback Treibstoff; Anderer Treibstoff"
          : isIncorreclyRefueled
          ? "Callback Treibstoff; Anderer falscher Treibstoff"
          : "Callback Treibstoff; Anderer Treibstoff";
      case FuelTypeOptions.ELECTRIC:
        return isIncorreclyRefueled
          ? "Falscher Treibstoff: Elektrisch"
          : "Korrekter Treibstoff: Elektrisch";
      case FuelTypeOptions.DIESEL:
        return isIncorreclyRefueled
          ? "Falscher Treibstoff: Diesel"
          : "Korrekter Treibstoff: Diesel";
      case FuelTypeOptions.ADBLUE:
        return isIncorreclyRefueled
          ? "Falscher Treibstoff: Adblue"
          : "Korrekter Treibstoff: Adblue";
      case FuelTypeOptions.UNLEADED_GAS:
        return isIncorreclyRefueled
          ? "Falscher Treibstoff: Bleifrei"
          : "Anderes Treibstoffproblem; Korrekter Treibstoff: Benzin oder Gas";
      default:
        return undefined;
    }
  }

  private static getCaseComment(
    data: FuelDetails,
    personDetail: PersonDetail,
    immobilizedOnHighway: boolean | undefined,
    immobilizationType: ImmobilizationType
  ): string {
    const comments: string[] = [];

    if (data.breakdownType === FuelIncidentType.INCORRECTLY_REFUELED) {
      comments.push("Callback Fehlbetankung durch THD");
    }

    if (data.breakdownType === FuelIncidentType.FUEL_TANK_EV_CONNECTOR) {
      comments.push("Callback THD nach Vervollständigung Dossier durch FA");
    }

    return [
      LocationUtils.getCaseComment(immobilizedOnHighway, immobilizationType),
      ...comments,
      PersonalDetailsUtils.serializeCaseComment(personDetail),
      data.breakdownType === FuelIncidentType.EMPTY_TANK
        ? undefined
        : this.getCaseCommentBasedOnFuelLiters(
            data.fuelType,
            +data.wrongFuelLiters
          ),
      ...PersonalDetailsUtils.serializeCoverageComment(personDetail),
    ]
      .filter(Boolean)
      .join("; ");
  }

  private static getCaseCommentBasedOnFuelLiters(
    fuelType: string,
    liters: number
  ): string | undefined {
    if (fuelType === FuelTypeOptions.DIESEL) {
      if (liters <= 1.5) {
        return "HOP";
      } else if (liters <= 5) {
        return "Callback";
      } else {
        return undefined;
      }
    }

    if (fuelType === FuelTypeOptions.UNLEADED_GAS) {
      if (liters <= 3) {
        return "HOP";
      } else if (liters <= 10) {
        return "Callback";
      } else {
        return undefined;
      }
    }

    return undefined;
  }

  private static getPriority(
    immobilizationType: ImmobilizationType,
    data: FuelDetails,
    appointment: Appointment | undefined
  ): number {
    const appointmentPriority = AppointmentUtils.getPriority(appointment);
    let basePriority: number | undefined;

    switch (data.breakdownType) {
      case FuelIncidentType.FUEL_LOSS:
        basePriority = appointmentPriority || 1;
        break;
      case FuelIncidentType.FUEL_TANK_EV_CONNECTOR:
        basePriority = appointmentPriority || 3;
        break;
      default:
        basePriority = appointmentPriority;
    }

    return HighwayUtils.serializePriority(immobilizationType, basePriority);
  }
}
