import { useEffect, useState } from "react";

// Extend the Window interface to include UC_UI
declare global {
  interface Window {
    UC_UI: any;
  }
}

export function setUsercentricsLanguage(language: string) {
  if (process.env.REACT_APP_CONSENT_MGMT !== "false") {
    const existingScript = document.getElementById("usercentrics-cmp");
    if (existingScript) {
      existingScript.remove(); // Remove existing script to reload it with new language
    }

    const script = document.createElement("script");
    script.id = "usercentrics-cmp";
    script.setAttribute("data-block-data-layer-push", "1");
    script.setAttribute("data-language", language); // Set the updated language
    script.setAttribute("async", "async");
    script.setAttribute(
      "data-settings-id",
      process.env.REACT_APP_CONSENT_MGMT as string
    );
    script.src = "https://app.usercentrics.eu/browser-ui/latest/loader.js";

    document.head.appendChild(script);
  }
}

function useUserCentrics() {
  const [isUserCentricsReady, setIsUserCentricsReady] = useState(false);

  useEffect(() => {
    const handleUserCentricsInit = () => {
      if (window.UC_UI) {
        setIsUserCentricsReady(true); // Set state to indicate that UC_UI is ready
      }
    };

    // Listen for the UC_UI_INITIALIZED event
    window.addEventListener("UC_UI_INITIALIZED", handleUserCentricsInit);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("UC_UI_INITIALIZED", handleUserCentricsInit);
    };
  }, []);

  return isUserCentricsReady;
}

export default useUserCentrics;
