import "./DoorsAndKeysIncident.scss";

import {
  IonButton,
  IonContent,
  IonIcon,
  IonLabel,
  IonTextarea,
  IonRadioGroup,
  IonItem,
  IonRadio,
} from "@ionic/react";
import { useStateMachine } from "little-state-machine";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { FormikProvider, useFormik } from "formik";
import { FormValidation } from "../../../helpers/FormValidation";
import React, { useRef } from "react";
import { updateSteps } from "../../../state/updateState";
import { MainFooter, MainHeader } from "../../Layout/Layout";
import {
  DoorsAndKeysDetails,
  DoorsAndKeysIncidentType,
} from "../../../model/Incident/DoorsAndKeysDetails";
import { useTranslation } from "react-i18next";
import { IncidentUtils } from "../../../helpers/IncidentUtils";
import { IncidentType } from "../../../model/IncidentType.enum";
import { ScrollToError } from "../../shared/ScrollToError/scroll-to-error";

const incidentOptions = [
  {
    label: DoorsAndKeysIncidentType.KEY_REMOTE_PROBLEM,
    value: DoorsAndKeysIncidentType.KEY_REMOTE_PROBLEM,
  },
  {
    label: DoorsAndKeysIncidentType.KEY_REMOTE_LOCKED_IN_CAR,
    value: DoorsAndKeysIncidentType.KEY_REMOTE_LOCKED_IN_CAR,
  },
  {
    label: DoorsAndKeysIncidentType.FUEL_CAP_JAMMED,
    value: DoorsAndKeysIncidentType.FUEL_CAP_JAMMED,
  },
  {
    label: DoorsAndKeysIncidentType.LOST_KEY_REMOTE,
    value: DoorsAndKeysIncidentType.LOST_KEY_REMOTE,
  },
  {
    label: DoorsAndKeysIncidentType.OTHER,
    value: DoorsAndKeysIncidentType.OTHER,
  },
];

const lockedOptions = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

const DoorsAndKeysIncident: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { actions, state } = useStateMachine({ updateSteps });

  const textArea = useRef<HTMLIonTextareaElement>(null);
  const requiredBreakdownField = t("Breakdown type is a required field");
  const requiredAdditionalField = t(
    "Additional information is a required field"
  );
  const requiredField = t("This is a required field");
  const radioStart =
    process.env.REACT_APP_RADIO_START &&
    process.env.REACT_APP_RADIO_START == "true"
      ? "start"
      : "end";
  const radioLockedStart =
    process.env.REACT_APP_RADIO_LOCK_START &&
    process.env.REACT_APP_RADIO_START == "true"
      ? "start"
      : "end";

  const validationSchema = Yup.object({
    breakdownType: Yup.string()
      .required(requiredBreakdownField)
      .oneOf(
        Object.values(DoorsAndKeysIncidentType) as string[],
        requiredBreakdownField
      ),
    isLocked: Yup.boolean().when("breakdownType", {
      is: (breakdownType: string) =>
        breakdownType !== DoorsAndKeysIncidentType.FUEL_CAP_JAMMED,
      then: Yup.boolean().required(requiredField),
    }),
    additionalInformation: Yup.string().when("breakdownType", {
      is: (breakdownType: string) =>
        breakdownType === DoorsAndKeysIncidentType.OTHER,
      then: Yup.string().required(requiredAdditionalField),
    }),
  });

  const getInitialValues = (): DoorsAndKeysDetails => {
    const initialValues =
      state.steps.incidentDetails &&
      state.steps.incidentType === IncidentType.DOOR_AND_KEYS
        ? state.steps.incidentDetails
        : { breakdownType: "", isLocked: undefined, additionalInformation: "" };
    return initialValues as DoorsAndKeysDetails;
  };

  const formik = useFormik({
    initialValues: getInitialValues(),
    validationSchema,
    onSubmit: (data: DoorsAndKeysDetails) => handleSubmit(data),
    validate: () => {
      const shouldScroll =
        formik.values.breakdownType &&
        formik.values.isLocked !== undefined &&
        !formik.values.additionalInformation;
      if (shouldScroll && textArea && textArea.current) {
        textArea.current.scrollIntoView();
      }
    },
  });

  const handleSubmit = (doorsAndKeysIncident: DoorsAndKeysDetails) => {
    if (
      state.isInEditMode &&
      doorsAndKeysIncident.isLocked &&
      state.steps.schedule.date &&
      state.steps.schedule.startTimeRange &&
      state.steps.schedule.endTimeRange &&
      state.steps.schedule.time
    ) {
      // reset appointment
      actions.updateSteps({
        schedule: {
          date: "",
          startTimeRange: "",
          endTimeRange: "",
          time: "",
        },
      });
    }
    actions.updateSteps({ incidentDetails: doorsAndKeysIncident });
    IncidentUtils.goToNextRoute(
      state.isInEditMode,
      state.steps.immobilizedOnHighway,
      history,
      state.summary
    );
  };

  return (
    <IonContent>
      <FormikProvider value={formik}>
        <ScrollToError />
        <form
          className="doors-and-keys-component"
          onSubmit={formik.handleSubmit}
        >
          <MainHeader
            imageTitle={t("Door & Keys")}
            image={`${process.env.PUBLIC_URL}/assets/images/${
              process.env.REACT_APP_ALT_ICONS_PATH || ""
            }Door & Keys.svg`}
          >
            <section>
              <h3>{t("What is the problem?")}</h3>
              <IonRadioGroup
                value={formik.values.breakdownType}
                name="breakdownType" // should have the same name as the form
                onIonChange={(e) => {
                  formik.setFieldValue("breakdownType", e.detail.value);
                  formik.setFieldValue("isLocked", undefined);
                }}
              >
                {incidentOptions.map(({ label, value }, i) => {
                  return (
                    <IonItem key={i}>
                      <span className={"tab capitalize"}>{t(label)}</span>
                      <IonRadio slot={radioStart} value={value} />
                    </IonItem>
                  );
                })}
              </IonRadioGroup>
              {FormValidation.getFormErrorMessage("breakdownType", formik, t)}
            </section>
            {formik.values.breakdownType &&
              formik.values.breakdownType !==
                DoorsAndKeysIncidentType.FUEL_CAP_JAMMED && (
                <section>
                  <h3>{t("Is anyone locked in the vehicle?")}</h3>
                  <IonRadioGroup
                    className="locked-inside-question"
                    name="isLocked" // should have the same name as the form
                    value={formik.values.isLocked}
                    onIonChange={(e) => {
                      formik.setFieldValue("isLocked", e.detail.value);
                    }}
                  >
                    {lockedOptions.map(({ label, value }, i) => {
                      return (
                        <IonItem key={i} className="options">
                          <span className={"tab capitalize"}>{t(label)}</span>
                          <IonRadio slot={radioLockedStart} value={value} />
                        </IonItem>
                      );
                    })}
                  </IonRadioGroup>
                  {FormValidation.getFormErrorMessage("isLocked", formik, t)}
                </section>
              )}
            <section>
              <h6>
                {t("Additional information")}
                {IncidentUtils.displayOptionalTag(
                  state.isInEditMode
                    ? state.summary.incidentType
                    : state.steps.incidentType,
                  formik.values.breakdownType,
                  t
                )}
              </h6>
              <IonTextarea
                rows={5}
                ref={textArea}
                id="additionalInformation"
                name="additionalInformation"
                value={formik.values.additionalInformation}
                onIonChange={formik.handleChange}
              />
              {FormValidation.getFormErrorMessage(
                "additionalInformation",
                formik,
                t
              )}
            </section>
          </MainHeader>
          <MainFooter>
            {!state.isInEditMode && (
              <IonButton
                id="doors-keys-back"
                className="button-tcs back"
                expand="block"
                onClick={() => history.back()}
              >
                <div className="wrapper">
                  <IonIcon
                    size="large"
                    src={`${process.env.PUBLIC_URL}/assets/images/chevron-back.svg`}
                  />
                  <IonLabel>{t("Back")}</IonLabel>
                  <div />
                </div>
              </IonButton>
            )}
            {state.isInEditMode && (
              <IonButton
                id="doors-keys-cancel"
                className="button-tcs"
                expand="block"
                onClick={() => history.back()}
              >
                <IonLabel>{t("Cancel")}</IonLabel>
              </IonButton>
            )}
            {!state.isInEditMode && (
              <IonButton
                id="doors-keys-next"
                className="button-tcs cta"
                color="primary"
                expand="block"
                onClick={() => formik.handleSubmit()}
              >
                <div className="wrapper">
                  <div />
                  <IonLabel>{t("Next")}</IonLabel>
                  <IonIcon
                    slot="end"
                    size="large"
                    src={`${process.env.PUBLIC_URL}/assets/images/chevron-forward.svg`}
                  />
                </div>
              </IonButton>
            )}
            {state.isInEditMode && (
              <IonButton
                id="doors-keys-save"
                className="button-tcs cta"
                color="primary"
                expand="block"
                onClick={() => formik.handleSubmit()}
              >
                <IonLabel>{t("Save")}</IonLabel>
              </IonButton>
            )}
          </MainFooter>
        </form>
      </FormikProvider>
    </IonContent>
  );
};

export default DoorsAndKeysIncident;
