import { InsuranceProviderByLanguageSchema } from "../model/InsuranceProvider";

export const TCS_INSURANCE_PHONE_NUMBER = "0800 140 140";
export const TCS_INSURANCE_PHONE_NUMBER_ABROAD = "+41 58 827 22 20";

export const insruanceProviders: InsuranceProviderByLanguageSchema = {
  de: [
    {
      icon: "",
      name: "Allianz",
      phone: "0800 22 33 44",
      email: "",
      url: "https://www.allianz.ch/crp/fnol/motor/ui/de-CH#(fnol-retail:/start-new-claim)",
      phoneAbroad: "+41 43 311 99 11",
      emailAbroad: "",
      urlAbroad:
        "https://www.allianz.ch/crp/fnol/motor/ui/de-CH#(fnol-retail:/start-new-claim)",
    },
    {
      icon: "",
      name: "Baloise",
      phone: "00800 24 800 800",
      email: "schaden@baloise.ch",
      url: "https://www.baloise.ch/app/claim-private/welcome?lang=de",
      phoneAbroad: "+41 58 285 28 28",
      emailAbroad: "schaden@baloise.ch",
      urlAbroad: "https://www.baloise.ch/app/claim-private/welcome?lang=de",
    },
    {
      icon: "",
      name: "Elvia",
      phone: "044 202 00 00",
      email: "",
      url: "https://www.elvia.ch/de/schaden-melden.html",
      phoneAbroad: "+41 43 311 99 11",
      emailAbroad: "",
      urlAbroad: "https://www.elvia.ch/de/schaden-melden.html",
    },
    {
      icon: "",
      name: "Helvetia",
      phone: "058 280 30 00",
      email: "",
      url: "https://www.helvetia.com/ch/web/de/privatkunden/kontakt/schaden-melden/schaden-fahrzeug.html#/entry",
      phoneAbroad: "+41 58 280 30 00",
      emailAbroad: "",
      urlAbroad:
        "https://www.helvetia.com/ch/web/de/privatkunden/kontakt/schaden-melden/schaden-fahrzeug.html#/entry",
    },
    {
      icon: "",
      name: "Die Mobiliar",
      phone: "00800 16 16 16 16",
      email: "",
      url: "https://secure.mobiliar.ch/mcs-osm-b2c/mobi/public/osm/1?language=de",
      phoneAbroad: "+41 31 389 81 81",
      emailAbroad: "",
      urlAbroad:
        "https://secure.mobiliar.ch/mcs-osm-b2c/mobi/public/osm/1?language=de",
    },
    {
      icon: "",
      name: "Smile",
      phone: "+41 800 848 488",
      email: "",
      url: "https://www.smile-insurances.com/ch/main/de/home/service/schadenmeldung/schadenmeldung-auto.html",
      phoneAbroad: "+41 216 18 88 88",
      emailAbroad: "",
      urlAbroad:
        "https://www.smile-insurances.com/ch/main/de/home/service/schadenmeldung/schadenmeldung-auto.html",
    },
    {
      icon: "",
      name: "Vaudoise",
      phone: "0800 811 911",
      email: "",
      url: "https://www.vaudoise.ch/de/service-center/schaden-melden",
      phoneAbroad: "+41 216 18 88 88",
      emailAbroad: "",
      urlAbroad: "https://www.vaudoise.ch/de/service-center/schaden-melden",
    },
    {
      icon: "",
      name: "AXA",
      phone: "0800 809 809",
      email: "schaden@axa.ch",
      url: "https://www.axa.ch/de/privatkunden/schaden/schadenmeldung/schaden-autoversicherung.html",
      phoneAbroad: "+41 58 218 11 00",
      emailAbroad: "schaden@axa.ch",
      urlAbroad:
        "https://www.axa.ch/de/privatkunden/schaden/schadenmeldung/schaden-autoversicherung.html",
    },
    {
      icon: "",
      name: "Zurich",
      phone: "0800 80 80 80",
      email: "",
      url: "https://www.zurich.ch/de/kontakt-services",
      phoneAbroad: "+41 44 628 98 98",
      emailAbroad: "",
      urlAbroad: "https://www.zurich.ch/de/kontakt-services",
    },
    {
      icon: "",
      name: "TCS Autoversicherung",
      phone: "0848 848 800",
      email: "",
      url: "https://www.tcs.ch/de/kontakt-fragen/notruf-schaden/schadenmeldung-fahrzeug.php#anchor_auto-motorrad",
      phoneAbroad: "+41 58 285 96 00",
      emailAbroad: "",
      urlAbroad:
        "https://www.tcs.ch/de/kontakt-fragen/notruf-schaden/schadenmeldung-fahrzeug.php#anchor_auto-motorrad",
    },
  ],
  fr: [
    {
      icon: "",
      name: "Allianz",
      phone: "0800 22 33 44",
      email: "",
      url: "https://www.allianz.ch/crp/fnol/motor/ui/fr-CH#(fnol-retail:/start-new-claim)",
      phoneAbroad: "+41 43 311 99 11",
      emailAbroad: "",
      urlAbroad:
        "https://www.allianz.ch/crp/fnol/motor/ui/fr-CH#(fnol-retail:/start-new-claim)",
    },
    {
      icon: "",
      name: "Baloise",
      phone: "00800 24 800 800",
      email: "schaden@baloise.ch",
      url: "https://www.baloise.ch/app/claim-private/welcome?lang=fr",
      phoneAbroad: "+41 58 285 28 28",
      emailAbroad: "schaden@baloise.ch",
      urlAbroad: "https://www.baloise.ch/app/claim-private/welcome?lang=fr",
    },
    {
      icon: "",
      name: "Elvia",
      phone: "044 202 00 00",
      email: "",
      url: "https://www.elvia.ch/fr/declaration-de-sinistre.html",
      phoneAbroad: "+41 43 311 99 11",
      emailAbroad: "",
      urlAbroad: "https://www.elvia.ch/fr/declaration-de-sinistre.html",
    },
    {
      icon: "",
      name: "Helvetia",
      phone: "058 280 30 00",
      email: "",
      url: "https://www.helvetia.com/ch/web/fr/personnes-privees/contact/declarer-sinistre/sinistre-vehicule.html#/entry",
      phoneAbroad: "+41 58 280 30 00",
      emailAbroad: "",
      urlAbroad:
        "https://www.helvetia.com/ch/web/fr/personnes-privees/contact/declarer-sinistre/sinistre-vehicule.html#/entry",
    },
    {
      icon: "",
      name: "La Mobilière",
      phone: "00800 16 16 16 16",
      email: "",
      url: "https://secure.mobiliar.ch/mcs-osm-b2c/mobi/public/osm/1?language=fr",
      phoneAbroad: "+41 31 389 81 81",
      emailAbroad: "",
      urlAbroad:
        "https://secure.mobiliar.ch/mcs-osm-b2c/mobi/public/osm/1?language=fr",
    },
    {
      icon: "",
      name: "Smile",
      phone: "+41 800 848 488",
      email: "",
      url: "https://www.smile-insurances.com/ch/main/fr/home/services/declaration-de-sinistre/damage-car.html",
      phoneAbroad: "+41 216 18 88 88",
      emailAbroad: "",
      urlAbroad:
        "https://www.smile-insurances.com/ch/main/fr/home/services/declaration-de-sinistre/damage-car.html",
    },
    {
      icon: "",
      name: "Vaudoise",
      phone: "0800 811 911",
      email: "",
      url: "https://www.vaudoise.ch/fr/service-center/declarer-un-sinistre",
      phoneAbroad: "+41 216 18 88 88",
      emailAbroad: "",
      urlAbroad:
        "https://www.vaudoise.ch/fr/service-center/declarer-un-sinistre",
    },
    {
      icon: "",
      name: "AXA",
      phone: "0800 809 809",
      email: "schaden@axa.ch",
      url: "https://www.axa.ch/fr/particuliers/sinistres/declarer-sinistre/declaration-sinistre-assurance-auto.html",
      phoneAbroad: "+41 58 218 11 00",
      emailAbroad: "schaden@axa.ch",
      urlAbroad:
        "https://www.axa.ch/fr/particuliers/sinistres/declarer-sinistre/declaration-sinistre-assurance-auto.html",
    },
    {
      icon: "",
      name: "Zurich",
      phone: "0800 80 80 80",
      email: "",
      url: "https://www.zurich.ch/fr/contact-services",
      phoneAbroad: "+41 44 628 98 98",
      emailAbroad: "",
      urlAbroad: "https://www.zurich.ch/fr/contact-services",
    },
    {
      icon: "",
      name: "TCS Assurance auto",
      phone: "0848 848 800",
      email: "",
      url: "https://www.tcs.ch/fr/contact-questions/urgence-sinistre/declaration-sinistre-vehicule.php",
      phoneAbroad: "+41 58 285 96 00",
      emailAbroad: "",
      urlAbroad:
        "https://www.tcs.ch/fr/contact-questions/urgence-sinistre/declaration-sinistre-vehicule.php",
    },
  ],
  it: [
    {
      icon: "",
      name: "Allianz",
      phone: "0800 22 33 44",
      email: "",
      url: "https://www.allianz.ch/crp/fnol/motor/ui/it-CH#(fnol-retail:/start-new-claim)",
      phoneAbroad: "+41 43 311 99 11",
      emailAbroad: "",
      urlAbroad:
        "https://www.allianz.ch/crp/fnol/motor/ui/it-CH#(fnol-retail:/start-new-claim)",
    },
    {
      icon: "",
      name: "Baloise",
      phone: "00800 24 800 800",
      email: "schaden@baloise.ch",
      url: "https://www.baloise.ch/app/claim-private/welcome?lang=it",
      phoneAbroad: "+41 58 285 28 28",
      emailAbroad: "schaden@baloise.ch",
      urlAbroad: "https://www.baloise.ch/app/claim-private/welcome?lang=it",
    },
    {
      icon: "",
      name: "Elvia",
      phone: "044 202 00 00",
      email: "",
      url: "https://www.elvia.ch/it/denuncia-di-sinistro.html",
      phoneAbroad: "+41 43 311 99 11",
      emailAbroad: "",
      urlAbroad: "https://www.elvia.ch/it/denuncia-di-sinistro.html",
    },
    {
      icon: "",
      name: "Helvetia",
      phone: "058 280 30 00",
      email: "",
      url: "https://www.helvetia.com/ch/web/it/clienti-privati/contatto/notificare-sinistro/sinistro-veicoli.html#/entry",
      phoneAbroad: "+41 58 280 30 00",
      emailAbroad: "",
      urlAbroad:
        "https://www.helvetia.com/ch/web/it/clienti-privati/contatto/notificare-sinistro/sinistro-veicoli.html#/entry",
    },
    {
      icon: "",
      name: "La Mobiliare",
      phone: "00800 16 16 16 16",
      email: "",
      url: "https://secure.mobiliar.ch/mcs-osm-b2c/mobi/public/osm/1?language=it",
      phoneAbroad: "+41 31 389 81 81",
      emailAbroad: "",
      urlAbroad:
        "https://secure.mobiliar.ch/mcs-osm-b2c/mobi/public/osm/1?language=it",
    },
    {
      icon: "",
      name: "Smile",
      phone: "+41 800 848 488",
      email: "",
      url: "https://www.smile-insurances.com/ch/main/it/home/servizi/notificare-sinistro/notifica-sinistro-auto.html",
      phoneAbroad: "+41 216 18 88 88",
      emailAbroad: "",
      urlAbroad:
        "https://www.smile-insurances.com/ch/main/it/home/servizi/notificare-sinistro/notifica-sinistro-auto.html",
    },
    {
      icon: "",
      name: "Vaudoise",
      phone: "0800 811 911",
      email: "",
      url: "https://www.vaudoise.ch/it/service-center/notificare-un-sinistro",
      phoneAbroad: "+41 216 18 88 88",
      emailAbroad: "",
      urlAbroad:
        "https://www.vaudoise.ch/it/service-center/notificare-un-sinistro",
    },
    {
      icon: "",
      name: "AXA",
      phone: "0800 809 809",
      email: "schaden@axa.ch",
      url: "https://www.axa.ch/it/clienti-privati/danni/notificare-un-sinistro/sinistro-assicurazione-auto.html",
      phoneAbroad: "+41 58 218 11 00",
      emailAbroad: "schaden@axa.ch",
      urlAbroad:
        "https://www.axa.ch/it/clienti-privati/danni/notificare-un-sinistro/sinistro-assicurazione-auto.html",
    },
    {
      icon: "",
      name: "Zurich",
      phone: "0800 80 80 80",
      email: "",
      url: "https://www.zurich.ch/it/contatto-servizio",
      phoneAbroad: "+41 44 628 98 98",
      emailAbroad: "",
      urlAbroad: "https://www.zurich.ch/it/contatto-servizio",
    },
    {
      icon: "",
      name: "TCS Assicurazione auto",
      phone: "0848 848 800",
      email: "",
      url: "https://www.tcs.ch/it/contatto-domande/caso-di-urgenza/dichiarazione-sinistro-veicolo.php",
      phoneAbroad: "+41 58 285 96 00",
      emailAbroad: "",
      urlAbroad:
        "https://www.tcs.ch/it/contatto-domande/caso-di-urgenza/dichiarazione-sinistro-veicolo.php",
    },
  ],
  en: [
    {
      icon: "",
      name: "Allianz",
      phone: "0800 22 33 44",
      email: "",
      url: "",
      phoneAbroad: "+41 43 311 99 11",
      emailAbroad: "",
      urlAbroad: "",
    },
    {
      icon: "",
      name: "Baloise",
      phone: "00800 24 800 800",
      email: "schaden@baloise.ch",
      url: "https://www.baloise.ch/app/claim-private/welcome?lang=en",
      phoneAbroad: "+41 58 285 28 28",
      emailAbroad: "schaden@baloise.ch",
      urlAbroad: "https://www.baloise.ch/app/claim-private/welcome?lang=en",
    },
    {
      icon: "",
      name: "Elvia",
      phone: "044 202 00 00",
      email: "",
      url: "https://www.elvia.ch/en/claims.html",
      phoneAbroad: "+41 43 311 99 11",
      emailAbroad: "",
      urlAbroad: "https://www.elvia.ch/en/claims.html",
    },
    {
      icon: "",
      name: "Helvetia",
      phone: "058 280 30 00",
      email: "",
      url: "https://www.helvetia.com/ch/web/en/private-customers/contact/report-claim/claim-vehicle.html#/entry",
      phoneAbroad: "+41 58 280 30 00",
      emailAbroad: "",
      urlAbroad:
        "https://www.helvetia.com/ch/web/en/private-customers/contact/report-claim/claim-vehicle.html#/entry",
    },
    {
      icon: "",
      name: "Die Mobiliar",
      phone: "00800 16 16 16 16",
      email: "",
      url: "",
      phoneAbroad: "+41 31 389 81 81",
      emailAbroad: "",
      urlAbroad: "",
    },
    {
      icon: "",
      name: "Smile",
      phone: "+41 800 848 488",
      email: "",
      url: "",
      phoneAbroad: "+41 216 18 88 88",
      emailAbroad: "",
      urlAbroad: "",
    },
    {
      icon: "",
      name: "AXA",
      phone: "0800 809 809",
      email: "schaden@axa.ch",
      url: "https://www.axa.ch/en/private-customers/claims/file-claim/car-insurance-claims.html",
      phoneAbroad: "+41 58 218 11 00",
      emailAbroad: "schaden@axa.ch",
      urlAbroad:
        "https://www.axa.ch/en/private-customers/claims/file-claim/car-insurance-claims.html",
    },
    {
      icon: "",
      name: "Zurich",
      phone: "0800 80 80 80",
      email: "",
      url: "https://www.zurich.ch/en/contact-services",
      phoneAbroad: "+41 44 628 98 98",
      emailAbroad: "",
      urlAbroad: "https://www.zurich.ch/en/contact-services",
    },
    {
      icon: "",
      name: "TCS Auto Insurance",
      phone: "0848 848 800",
      email: "",
      url: "",
      phoneAbroad: "+41 58 285 96 00",
      emailAbroad: "",
      urlAbroad: "",
    },
  ],
};
