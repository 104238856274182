import "./InsuranceProvider.scss";

import { IonButton, IonContent, IonLabel } from "@ionic/react";
import { useHistory } from "react-router";

import { useTranslation } from "react-i18next";

import React, { useState } from "react";
import { IncidentType } from "../../model/IncidentType.enum";
import { MainFooter, MainHeader } from "../Layout/Layout";
import { insruanceProviders } from "../../constants/InsuranceProviders";
import {
  InsuranceProviderByLanguageSchema,
  InsuranceProviderSchema,
} from "../../model/InsuranceProvider";
import CallTCSModal from "./CallTCSModal";
import { useStateMachine } from "little-state-machine";
import { updateInsuranceCompanyInfo } from "../../state/updateState";
import { RoutePath } from "../../model/RoutePath.enum";
import i18n from "../../i18n/i18n.config";

const InsuranceProvider: React.FC = () => {
  const { t } = useTranslation();
  const { actions } = useStateMachine({
    updateInsuranceCompanyInfo,
  });
  let icon = "Accident.svg";
  let title = IncidentType.ACCIDENT;
  const history = useHistory();
  const [isCallTcsModalOpen, setIsCallTcsModalOpen] = useState(false); // Track modal open state

  // Handle dismiss of InsuranceModalType to allow reopening
  function handleModalDismiss() {
    setIsCallTcsModalOpen(false); // Reset the state so it can be reopened
  }

  // Handle dismiss of InstructionsModal to allow reopening
  function handleInsuranceProviderChoosen(provider: InsuranceProviderSchema) {
    actions.updateInsuranceCompanyInfo({ ...provider });
    history.push(`${RoutePath.REQUEST}/${RoutePath.INSURANCE_PROVIDER_INFO}`);
  }

  return (
    <IonContent>
      <MainHeader
        title={t(title)}
        image={`${process.env.PUBLIC_URL}/assets/images/${
          process.env.REACT_APP_ALT_ICONS_PATH || ""
        }${icon}`}
        titleStyle={{ textAlign: "center" }}
      >
        <section id="insurance-info-text">
          <p className="text-info-4">
            {t(
              "In order to benefit from the most cost-effective accident assistance, we recommend that you first contact your vehicle insurance company. They will be able to guide you through the process and help you manage any possible expenses efficiently."
            )}
          </p>
          <p className="text-info-4">
            {t(
              "Select your insurance provider from the list below to contact them directly:"
            )}
          </p>
          <div className="button-container-insurance">
            <div className="button-grid-insurance">
              {
                // Fetch the array based on the current language
                (
                  insruanceProviders[
                    i18n.language as keyof InsuranceProviderByLanguageSchema
                  ] || []
                ).map((provider: InsuranceProviderSchema, i: number) => (
                  <IonButton
                    key={`${provider.name}-${i}`}
                    id={`${provider.name}-${i}`}
                    className="button-tcs"
                    color="secondary"
                    expand="block"
                    type="button"
                    onClick={() => handleInsuranceProviderChoosen(provider)}
                  >
                    {provider.name}
                  </IonButton>
                ))
              }
            </div>
          </div>
          <p className="text-info-4">
            {t(
              "If for any reason your vehicle insurer can't help you, or if you need further assistance, don't hesitate to give us a call."
            )}
          </p>
          <div className="button-container-insurance">
            <div className="button-grid-insurance">
              <IonButton
                id="call-tcs-button"
                className="button-tcs"
                color="secondary"
                expand="block"
                type="button"
                onClick={() => setIsCallTcsModalOpen(true)}
              >
                {t("Call TCS")}
              </IonButton>
            </div>
          </div>
        </section>
      </MainHeader>
      <CallTCSModal
        isOpen={isCallTcsModalOpen}
        onClose={() => handleModalDismiss()}
      />
      <MainFooter>
        <IonButton
          id="immobilized-btn-back"
          className="button-tcs"
          expand="block"
          onClick={() => history.back()}
        >
          <IonLabel>{t("Back")}</IonLabel>
        </IonButton>
      </MainFooter>
    </IonContent>
  );
};

export default InsuranceProvider;
