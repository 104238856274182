import "./InsuranceProviderInfo.scss";

import { IonButton, IonContent, IonIcon, IonLabel } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { MainFooter, MainHeader } from "../Layout/Layout";
import { useStateMachine } from "little-state-machine";
import { useHistory } from "react-router";

const InsuranceProviderInfo: React.FC = () => {
  const { t } = useTranslation();
  const { state } = useStateMachine();
  const history = useHistory();

  return (
    <IonContent>
      <MainHeader
        title={state.steps.insuranceCompanyInfo.name}
        titleStyle={{ textAlign: "center" }}
        gridClassName="insurance-grid-style"
      >
        <p id="text-insurance-info">
          {t("For accidents within Switzerland and Liechtenstein") + ":"}
        </p>
        {state.steps.insuranceCompanyInfo.phone != "" && (
          <IonButton
            className="button-tcs"
            color="secondary"
            expand="block"
            type="button"
            href={`tel:${state.steps.insuranceCompanyInfo.phone}`}
          >
            <div className="wrapper-insurance-buttons">
              <IonIcon
                size="large"
                src={`${process.env.PUBLIC_URL}/assets/images/call.svg`}
              />
              <IonLabel>{state.steps.insuranceCompanyInfo.phone}</IonLabel>
            </div>
          </IonButton>
        )}
        {state.steps.insuranceCompanyInfo.email != "" && (
          <IonButton
            className="button-tcs"
            color="secondary"
            expand="block"
            type="button"
            href={`mailto:${state.steps.insuranceCompanyInfo.email}`}
          >
            <div className="wrapper-insurance-buttons">
              <IonIcon
                size="large"
                src={`${process.env.PUBLIC_URL}/assets/images/mail.svg`}
              />
              <IonLabel>{state.steps.insuranceCompanyInfo.email}</IonLabel>
            </div>
          </IonButton>
        )}
        {state.steps.insuranceCompanyInfo.url != "" && (
          <IonButton
            className="button-tcs"
            color="secondary"
            expand="block"
            type="button"
            href={state.steps.insuranceCompanyInfo.url}
          >
            <div className="wrapper-insurance-buttons">
              <IonIcon
                size="large"
                src={`${process.env.PUBLIC_URL}/assets/images/internet.svg`}
              />
              <IonLabel>{t("Declare online")}</IonLabel>
            </div>
          </IonButton>
        )}
        <p id="text-insurance-info">{t("For accidents abroad") + ":"}</p>
        {state.steps.insuranceCompanyInfo.phoneAbroad != "" && (
          <IonButton
            className="button-tcs"
            color="secondary"
            expand="block"
            type="button"
            href={`tel:${state.steps.insuranceCompanyInfo.phoneAbroad}`}
          >
            <div className="wrapper-insurance-buttons">
              <IonIcon
                size="large"
                src={`${process.env.PUBLIC_URL}/assets/images/call.svg`}
              />
              <IonLabel>
                {state.steps.insuranceCompanyInfo.phoneAbroad}
              </IonLabel>
            </div>
          </IonButton>
        )}
        {state.steps.insuranceCompanyInfo.emailAbroad != "" && (
          <IonButton
            className="button-tcs"
            color="secondary"
            expand="block"
            type="button"
            href={`mailto:${state.steps.insuranceCompanyInfo.emailAbroad}`}
          >
            <div className="wrapper-insurance-buttons">
              <IonIcon
                size="large"
                src={`${process.env.PUBLIC_URL}/assets/images/mail.svg`}
              />
              <IonLabel>
                {state.steps.insuranceCompanyInfo.emailAbroad}
              </IonLabel>
            </div>
          </IonButton>
        )}
        {state.steps.insuranceCompanyInfo.urlAbroad != "" && (
          <IonButton
            className="button-tcs"
            color="secondary"
            expand="block"
            type="button"
            href={state.steps.insuranceCompanyInfo.urlAbroad}
          >
            <div className="wrapper-insurance-buttons">
              <IonIcon
                size="large"
                src={`${process.env.PUBLIC_URL}/assets/images/internet.svg`}
              />
              <IonLabel>{t("Declare online")}</IonLabel>
            </div>
          </IonButton>
        )}
      </MainHeader>
      <MainFooter>
        <IonButton
          id="close-modal"
          className="button-tcs"
          expand="block"
          onClick={() => history.back()}
        >
          <IonLabel>{t("Close")}</IonLabel>
        </IonButton>
      </MainFooter>
    </IonContent>
  );
};

export default InsuranceProviderInfo;
