import "./CallTCSModal.scss";

import { IonButton, IonIcon, IonLabel, IonModal } from "@ionic/react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  TCS_INSURANCE_PHONE_NUMBER,
  TCS_INSURANCE_PHONE_NUMBER_ABROAD,
} from "../../constants/InsuranceProviders";

interface CallTCSModalProps {
  isOpen: boolean; // External control for the modal state
  onClose: () => void; // Handler for closing the modal
}

const CallTCSModal: React.FC<CallTCSModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const modal = useRef<HTMLIonModalElement>(null);

  function dismiss() {
    onClose(); // Close the modal through the external handler
  }

  return (
    <IonModal
      id="insurance-provider-modal"
      isOpen={isOpen}
      ref={modal}
      onDidDismiss={dismiss}
      title="TCS"
    >
      <div className="wrapper">
        <div className="icon-container">
          <h2 id="insurance-tcs-tittle">TCS</h2>
        </div>
        <div className="insurance-provider-modal-buttons">
          <p id="text-insurance-info-tcs">
            {t("For accidents within Switzerland and Liechtenstein") + ":"}
          </p>
          <IonButton
            className="button-tcs"
            color="secondary"
            expand="block"
            type="button"
            href={`tel:${TCS_INSURANCE_PHONE_NUMBER}`}
          >
            <div className="wrapper-insurance-buttons">
              <IonIcon
                size="large"
                src={`${process.env.PUBLIC_URL}/assets/images/call.svg`}
              />
              <IonLabel>{TCS_INSURANCE_PHONE_NUMBER}</IonLabel>
            </div>
          </IonButton>
          <p id="text-insurance-info-tcs">{t("For accidents abroad") + ":"}</p>
          <IonButton
            className="button-tcs"
            color="secondary"
            expand="block"
            type="button"
            href={`tel:${TCS_INSURANCE_PHONE_NUMBER_ABROAD}`}
          >
            <div className="wrapper-insurance-buttons">
              <IonIcon
                size="large"
                src={`${process.env.PUBLIC_URL}/assets/images/call.svg`}
              />
              <IonLabel>{TCS_INSURANCE_PHONE_NUMBER_ABROAD}</IonLabel>
            </div>
          </IonButton>
        </div>
        <IonButton
          id="close-modal"
          className="button-tcs"
          expand="block"
          onClick={dismiss}
        >
          <IonLabel>{t("Close")}</IonLabel>
        </IonButton>
      </div>
    </IonModal>
  );
};

export default CallTCSModal;
