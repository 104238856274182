import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { setUsercentricsLanguage } from "../userCentrics/userCentrics";

const enTranslations = require(`${process.env.REACT_APP_TRANSLATION_FOLDER_LOCATION}/en/translation.json`);
const frTranslations = require(`${process.env.REACT_APP_TRANSLATION_FOLDER_LOCATION}/fr/translation.json`);
const deTranslations = require(`${process.env.REACT_APP_TRANSLATION_FOLDER_LOCATION}/de/translation.json`);
const itTranslations = require(`${process.env.REACT_APP_TRANSLATION_FOLDER_LOCATION}/it/translation.json`);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    //debug: true,
    fallbackLng: "en",
    supportedLngs: ["en", "fr", "de", "it"],
    preload: ["en", "fr", "de", "it"],
    resources: {
      en: {
        translation: enTranslations,
      },
      de: {
        translation: deTranslations,
      },
      fr: {
        translation: frTranslations,
      },
      it: {
        translation: itTranslations,
      },
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

// Initial load with the current language
setUsercentricsLanguage(i18n.language);

// Listen for language changes
i18n.on("languageChanged", (newLanguage) => {
  setUsercentricsLanguage(newLanguage);
});

export default i18n;
