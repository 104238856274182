import "./ScheduleService.scss";
import "react-datepicker/dist/react-datepicker.css";

import { IonButton, IonContent, IonIcon, IonLabel } from "@ionic/react";
import { useStateMachine } from "little-state-machine";
import DatePicker from "react-datepicker";

import { useHistory } from "react-router";
import { RoutePath } from "../../model/RoutePath.enum";
import { updateSteps } from "../../state/updateState";
import { MainFooter, MainHeader } from "../Layout/Layout";
import React, { useState } from "react";
import {
  addMinutes,
  differenceInHours,
  format,
  isToday,
  addDays,
  parse,
  setHours,
  setMinutes,
} from "date-fns";
import Calendar from "../shared/Calendar/Calendar";
import * as Yup from "yup";
import { useFormik } from "formik";
import { FormValidation } from "../../helpers/FormValidation";
import { useTranslation } from "react-i18next";
import { Appointment } from "../../model/Appointment";

const dateFormat = "dd/MM/yyyy";
const hourFormat = "HH:mm";

const ScheduleService: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { actions, state } = useStateMachine({ updateSteps });
  const [pickedDay, setPickedDay] = useState(new Date());
  const [startTimeRange, setStartTimeRange] = useState(new Date());
  const [endTimeRange, setEndTimeRange] = useState(new Date());
  const [time, setTime] = useState(new Date());
  const [isFixedTime, setIsFixedTime] = useState<boolean>(
    process.env.REACT_APP_SCHEDULE_APPOINTMENT_FIXED_TIME === "true"
  );

  const validationSchema = Yup.object({
    date: Yup.string().required(t("Date is a required field")),
    time: Yup.string()
      .when("isFixedTime", {
        is: true, // If isFixedTime is true
        then: Yup.string().required(t("Time is a required field")),
        otherwise: Yup.string().notRequired(), // If isFixedTime is false, don't require the field
      })
      .test(
        "future_date_test",
        "Please select the date and time of your choice",
        function (value): boolean {
          let isInFuture = true;
          if (value && formik.values.date) {
            const minDate = setMinTimeDatePicker();
            const dateArray = formik.values.date.split("/");
            const timeArray = value.split(":");
            const currentPickedDate = new Date(
              Number(dateArray[2]),
              Number(dateArray[1]) - 1,
              Number(dateArray[0]),
              Number(timeArray[0]),
              Number(timeArray[1])
            );
            isInFuture = minDate < currentPickedDate;
          }
          return isInFuture;
        }
      ),
    // Conditionally validate startTimeRange and endTimeRange based on isFixedTime
    startTimeRange: Yup.string().when("isFixedTime", {
      is: false, // If isFixedTime is false
      then: Yup.string().required(t("Start time is a required field")),
      otherwise: Yup.string().notRequired(), // If isFixedTime is true, don't require the field
    }),

    endTimeRange: Yup.string().when("isFixedTime", {
      is: false, // If isFixedTime is false
      then: Yup.string().required(t("End time is a required field")),
      otherwise: Yup.string().notRequired(), // If isFixedTime is true, don't require the field
    }),
  });

  const formik = useFormik({
    initialValues: state.steps.schedule,
    validationSchema,
    onSubmit: (schedule: Appointment) => {
      actions.updateSteps({ schedule });
      history.push(`${RoutePath.REQUEST}/${RoutePath.SUMMARY}`);
    },
  });

  const handleDateChange = (result: string) => {
    formik.setFieldValue("date", result);

    const selectedDate = parse(result, dateFormat, new Date());
    setPickedDay(selectedDate);

    const minTime = setMinTimeDatePicker(selectedDate);
    const maxStartTime = setMaxTimeForStartTimeRange(selectedDate);
    const firstEnabledStartTime = findFirstEnabledTime(minTime, maxStartTime);

    if (isFixedTime) {
      setTime(firstEnabledStartTime);
      formik.setFieldValue("time", format(firstEnabledStartTime, hourFormat));
    } else {
      setStartTimeRange(firstEnabledStartTime);
      formik.setFieldValue(
        "startTimeRange",
        format(firstEnabledStartTime, hourFormat)
      );

      const firstEnabledEndTime = findFirstEnabledEndTime(
        firstEnabledStartTime,
        150
      );

      setEndTimeRange(firstEnabledEndTime);
      formik.setFieldValue(
        "endTimeRange",
        format(firstEnabledEndTime, hourFormat)
      );
      setTimeout(() => {
        const activeDates = document.querySelectorAll(
          ".react-datepicker__time-list-item--selected"
        );
        if (activeDates) {
          activeDates.forEach((e) => e.scrollIntoView({ behavior: "smooth" }));
        }
      }, 100);
    }
  };

  const handleTimeChange = (date: Date, fieldValue: string) => {
    // By default, date is current day and hour. Previously, the day didn't mattered, as every day were working the same,
    // So no issues in setting the wrong day with good hours. However now, it has to be set to the right day, as current day
    // doesn't have the same time range
    date.setDate(pickedDay.getDate());
    if (fieldValue === "startTimeRange") {
      setStartTimeRange(date);
      formik.setFieldValue("startTimeRange", format(date, hourFormat));
      if (endTimeRange) {
        const timeDifference = differenceInHours(endTimeRange, date);

        // If the difference between end Time and new start Time is less than 2 hours, we update the end time
        if (timeDifference < 2.5) {
          const minEndTime = findFirstEnabledEndTime(date, 150);
          setEndTimeRange(minEndTime);
          formik.setFieldValue("endTimeRange", format(minEndTime, hourFormat));
        }
      } else {
        const minEndTime = findFirstEnabledEndTime(date, 150);
        setEndTimeRange(minEndTime);
        formik.setFieldValue("endTimeRange", format(minEndTime, hourFormat));
      }
    } else if (fieldValue === "endTimeRange") {
      setEndTimeRange(date);
      formik.setFieldValue("endTimeRange", format(date, hourFormat));
    } else {
      setTime(date);
      formik.setFieldValue("time", format(date, hourFormat));
    }
    scrollIntoTimeDates();
  };

  const scrollIntoTimeDates = () => {
    setTimeout(() => {
      const activeDates = document.querySelectorAll(
        ".react-datepicker__time-list-item--selected"
      );
      if (activeDates) {
        activeDates.forEach((e) => e.scrollIntoView({ behavior: "smooth" }));
      }
    }, 100);
  };

  const setMinTimeDatePicker = (selectedDate = new Date()): Date => {
    const MIN_START_HOUR = 6;
    const MAX_END_HOUR = 21;

    if (isToday(selectedDate)) {
      const currentDate = new Date();
      const currentHours = currentDate.getHours();
      const currentMinutes = currentDate.getMinutes();
      if (currentHours >= MAX_END_HOUR) {
        // Past the last slot, return 6:00 the next day
        return setHours(setMinutes(addDays(currentDate, 1), 0), MIN_START_HOUR);
      }

      const currentSlotHour = currentHours < MIN_START_HOUR ? MIN_START_HOUR : currentHours;
      let nextSlot = new Date(currentDate);

      // Adjust the time to the next valid slot
      nextSlot.setHours(currentSlotHour);
      nextSlot.setMinutes(currentMinutes <= 30 ? 30 : 0);
      nextSlot = addMinutes(nextSlot, currentMinutes > 30 ? 60 : 0);

      // Add extra time if the current time leaves less than 30 minutes for preparation
      if (
          nextSlot.getTime() - currentDate.getTime() < 30 * 60 * 1000
      ) {
        nextSlot = addMinutes(nextSlot, 30);
      }

      // Ensure it's not before 6:00
      if (nextSlot.getHours() < MIN_START_HOUR) {
        nextSlot.setHours(MIN_START_HOUR);
        nextSlot.setMinutes(0);
      }

      // If after 21:00, move to the next day 6:00
      if (nextSlot.getHours() >= MAX_END_HOUR) {
        return setHours(setMinutes(addDays(nextSlot, 1), 0), MIN_START_HOUR);
      }

      return nextSlot;
    } else {
      // If not today, return 6:00
      return setHours(setMinutes(selectedDate, 0), MIN_START_HOUR);
    }
  };



  const setMaxTimeForStartTimeRange = (selectedDate = new Date()): Date => {
    return setHours(setMinutes(selectedDate, 0), 21); // Limit startTimeRange to be selectable only up to 21:00
  };

  const setMaxTimeDatePicker = (selectedDate = new Date()): Date => {
    return setHours(setMinutes(selectedDate, 30), 23); // Limit endTimeRange max to 23:00
  };

  const findFirstEnabledTime = (minTime: Date, maxTime: Date): Date => {
    let currentTime = minTime;

    // Increment in 30-minute intervals until we find an enabled time
    while (currentTime <= maxTime) {
      if (!computeTimeClassName(currentTime)) {
        return currentTime;
      }
      currentTime = addMinutes(currentTime, 30); // move to the next 30-minute interval
    }

    return minTime; // Fallback if no enabled time is found (unlikely if minTime is within range)
  };

  const findFirstEnabledEndTime = (
    startTime: Date,
    minOffset: number
  ): Date => {
    let currentTime = addMinutes(startTime, minOffset);
    const maxEndTime = setMaxTimeDatePicker(startTime);

    while (currentTime <= maxEndTime) {
      if (!computeTimeClassName(currentTime)) {
        return currentTime;
      }
      currentTime = addMinutes(currentTime, 30);
    }

    return maxEndTime;
  };

  const computeTimeClassName = (time: Date) => {
    return time.getHours() === 8 && time.getMinutes() === 0
      ? "react-datepicker__time-list-item--disabled"
      : "";
  };

  // General function to parse any time string in "HH:mm" format
  const parseSelectedTime = (timeString?: string): Date | undefined => {
    if (timeString) {
      const date = new Date(); // Create a new date object
      const timeArray = timeString.split(":"); // Split the time string into hours and minutes
      date.setHours(Number(timeArray[0])); // Set hours
      date.setMinutes(Number(timeArray[1])); // Set minutes
      return date; // Return the constructed date
    }
    return undefined; // Return undefined if no time string is provided
  };

  return (
    <IonContent>
      <form className="fluid-loss-component" onSubmit={formik.handleSubmit}>
        <MainHeader title={t("Please select the date and time of your choice")}>
          <div className="schedule-service-container">
            <div>
              <Calendar
                name="date"
                id="date"
                currentDate={parse(formik.values.date, dateFormat, new Date())}
                onDateChange={(result: string) => handleDateChange(result)}
              />
              {FormValidation.getFormErrorMessage("date", formik, t)}
            </div>
            {formik.values.date && !isFixedTime && (
              <>
                <div className="time-picker-container">
                  <DatePicker
                    name="startTimeRange"
                    id="startTimeRange"
                    value={formik.values.startTimeRange}
                    onChange={(date: Date) =>
                      handleTimeChange(date, "startTimeRange")
                    }
                    calendarClassName="time-picker-schedule-service"
                    selected={
                      parseSelectedTime(formik.values.startTimeRange) ||
                      startTimeRange
                    }
                    showTimeSelect
                    showTimeSelectOnly
                    minTime={setMinTimeDatePicker(startTimeRange)}
                    maxTime={setMaxTimeForStartTimeRange(startTimeRange)}
                    inline
                    timeCaption={t("Between")}
                    timeFormat="HH:mm"
                    timeIntervals={30}
                  />
                  {FormValidation.getFormErrorMessage(
                    "startTimeRange",
                    formik,
                    t
                  )}
                </div>
                <div className="time-picker-container">
                  <DatePicker
                    name="endTimeRange"
                    id="endTimeRange"
                    value={formik.values.endTimeRange}
                    onChange={(date: Date) =>
                      handleTimeChange(date, "endTimeRange")
                    }
                    calendarClassName="time-picker-schedule-service"
                    selected={
                      parseSelectedTime(formik.values.endTimeRange) ||
                      endTimeRange
                    }
                    showTimeSelect
                    showTimeSelectOnly
                    minTime={addMinutes(startTimeRange, 150)} // Set minimum time to 2 hours after startTimeRange
                    maxTime={setMaxTimeDatePicker()}
                    inline
                    timeCaption={t("And")}
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    timeClassName={computeTimeClassName}
                  />
                  {FormValidation.getFormErrorMessage(
                    "endTimeRange",
                    formik,
                    t
                  )}
                </div>
              </>
            )}
            {formik.values.date && isFixedTime && (
              <div className="time-picker-container">
                <DatePicker
                  name="time"
                  id="time"
                  value={formik.values.time}
                  onChange={(date: Date) => handleTimeChange(date, "time")}
                  calendarClassName="time-picker-schedule-service"
                  selected={parseSelectedTime(formik.values.time) || time}
                  showTimeSelect
                  showTimeSelectOnly
                  minTime={setHours(setMinutes(new Date(), 0), 6)}
                  maxTime={setHours(setMinutes(new Date(), 0), 21)}
                  inline
                  timeCaption="At"
                  timeFormat="HH:mm"
                  timeIntervals={30}
                />
                {FormValidation.getFormErrorMessage("time", formik, t)}
              </div>
            )}
          </div>
          <div className="information-container">
            <p className="information">
              {t(
                "Please note that 60 min before your appointment, a TCS agent will call to organize the next steps of service"
              )}
            </p>
          </div>
        </MainHeader>
        <MainFooter>
          {!state.isInEditMode && (
            <IonButton
              id="ss-back"
              className="button-tcs back"
              expand="block"
              onClick={() => history.back()}
            >
              <div className="wrapper">
                <IonIcon
                  size="large"
                  src={`${process.env.PUBLIC_URL}/assets/images/chevron-back.svg`}
                />
                <IonLabel>{t("Back")}</IonLabel>
                <div />
              </div>
            </IonButton>
          )}
          {state.isInEditMode && (
            <IonButton
              id="ss-cancel"
              className="button-tcs"
              expand="block"
              onClick={() => history.back()}
            >
              <IonLabel>{t("Cancel")}</IonLabel>
            </IonButton>
          )}
          {!state.isInEditMode && (
            <IonButton
              id="ss-next"
              className="button-tcs cta"
              color="primary"
              expand="block"
              onClick={() => formik.handleSubmit()}
            >
              <div className="wrapper">
                <div />
                <IonLabel>{t("Next")}</IonLabel>
                <IonIcon
                  slot="end"
                  size="large"
                  src={`${process.env.PUBLIC_URL}/assets/images/chevron-forward.svg`}
                />
              </div>
            </IonButton>
          )}
          {state.isInEditMode && (
            <IonButton
              id="ss-save"
              className="button-tcs cta"
              color="primary"
              expand="block"
              onClick={() => formik.handleSubmit()}
            >
              <IonLabel>{t("Save")}</IonLabel>
            </IonButton>
          )}
        </MainFooter>
      </form>
    </IonContent>
  );
};

export default ScheduleService;
