import { Case } from "../../api/models/Case";
import { IncidentUtils } from "../IncidentUtils";
import {
  EngineDetails,
  EngineIncidentOptions,
} from "../../model/Incident/EngineDetails";
import { Appointment } from "../../model/Appointment";
import { AppointmentUtils } from "../AppointmentUtils";
import { PersonDetail } from "../../model/PersonDetail";
import { PersonalDetailsUtils } from "../PersonalDetailsUtils";
import { ImmobilizationType } from "../../model/ImmobilizationType.enum";
import { HighwayUtils } from "../HighwayUtils";
import { LocationUtils } from "../LocationUtils";

export class EngineUtils {
  public static serialize(
    data: EngineDetails,
    appointment: Appointment | undefined,
    personalDetails: PersonDetail,
    immobilizationType: ImmobilizationType,
    immobilizedOnHighway: boolean | undefined
  ): Partial<Case> {
    return {
      MainCause: "12",
      Comment: this.getCaseComment(
        data.breakdownType,
        personalDetails,
        immobilizedOnHighway,
        immobilizationType
      ),
      Priority: this.getPriority(immobilizationType, data, appointment),
      ItemsUsedList: [
        {
          ...IncidentUtils.getItemType(),
          LastVehicleStatus: {
            PrimaryFault: this.getPrimaryFault(data.breakdownType),
            Comment: this.getTCSDescription(data.breakdownType)
              .concat("; ")
              .concat(
                IncidentUtils.formatAdditionalInformation(
                  data.additionalInformation
                )
              ),
          },
        },
      ],
    };
  }

  public static getSummary(data: EngineDetails): string {
    return data.breakdownType;
  }

  private static getPrimaryFault(breakdownType: string): string {
    switch (breakdownType) {
      case EngineIncidentOptions.LESS_POWER:
        return "65093";
      case EngineIncidentOptions.TURNED_OFF:
        return "65038";
      case EngineIncidentOptions.MOTOR_OVERHEATED_SMOKE:
        return "65041";
      case EngineIncidentOptions.WARNING_LIGHT:
        return "65090";
      default:
        // OTHER and UNUSUAL_NOISE have same code
        return "65076";
    }
  }

  private static getTCSDescription(breakdownType: string): string {
    switch (breakdownType) {
      case EngineIncidentOptions.LESS_POWER:
        return "Motor weniger Leistung oder stottert";
      case EngineIncidentOptions.TURNED_OFF:
        return "Motor selbständig abgestellt";
      case EngineIncidentOptions.MOTOR_OVERHEATED_SMOKE:
        return "Motor überhitzt; Callback Pannenart durch THD";
      case EngineIncidentOptions.UNUSUAL_NOISE:
        return "Ungewohntes Geräusch";
      case EngineIncidentOptions.WARNING_LIGHT:
        return "Callback Fragebaum";
      default:
        // Represents EngineIncidentOptions.OTHER
        return "Motorproblem";
    }
  }

  private static getCaseComment(
    breakdownType: string,
    personDetail: PersonDetail,
    immobilizedOnHighway: boolean | undefined,
    immobilizationType: ImmobilizationType
  ): string {
    const comments: string[] = [];
    switch (breakdownType) {
      case EngineIncidentOptions.WARNING_LIGHT:
        comments.push("Callback Fragebaum");
        break;
      default:
        break;
    }

    return [
      LocationUtils.getCaseComment(immobilizedOnHighway, immobilizationType),
      ...comments,
      PersonalDetailsUtils.serializeCaseComment(personDetail),
      ...PersonalDetailsUtils.serializeCoverageComment(personDetail),
    ]
      .filter(Boolean)
      .join("; ");
  }

  private static getPriority(
    immobilizationType: ImmobilizationType,
    data: EngineDetails,
    appointment: Appointment | undefined
  ): number {
    const appointmentPriority = AppointmentUtils.getPriority(appointment);
    let basePriority: number | undefined;

    switch (data.breakdownType) {
      case EngineIncidentOptions.WARNING_LIGHT:
        basePriority = appointmentPriority || 3;
        break;
      default:
        basePriority = appointmentPriority;
    }

    return HighwayUtils.serializePriority(immobilizationType, basePriority);
  }
}
