import "./WheelsAndTiresIncidentInsurance.scss";

import { IonButton, IonContent, IonLabel } from "@ionic/react";

import { useTranslation } from "react-i18next";

import React from "react";
import { IncidentType } from "../../../model/IncidentType.enum";
import { MainHeader } from "../../Layout/Layout";
import { useHistory } from "react-router";
import { RoutePath } from "../../../model/RoutePath.enum";

const WheelsAndTiresIncidentInsurance: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  let icon = "Wheel & Tire.svg";
  let title = IncidentType.WHEEL_AND_TIRE;

  function handleAnswer(answer: string) {
    if (answer === "Yes") {
      history.replace(`${RoutePath.REQUEST}/${RoutePath.INCIDENT_TYPE}`);
      history.push(`${RoutePath.REQUEST}/${RoutePath.INSURANCE_PROVIDER}`);
    }
    if (answer === "No") {
      history.replace(`${RoutePath.REQUEST}/${RoutePath.INCIDENT_TYPE}`);
      history.push(`${RoutePath.REQUEST}/${RoutePath.WHEELS_AND_TIRES}`);
    }
  }

  return (
    <IonContent>
      <MainHeader
        title={t(title)}
        image={`${process.env.PUBLIC_URL}/assets/images/${
          process.env.REACT_APP_ALT_ICONS_PATH || ""
        }${icon}`}
        titleStyle={{ textAlign: "center" }}
      >
        <section id="insurance-info-text">
          <p className="text-info-4">
            {t(
              "Is your issue the result of a collision (e.g., obstacle on the road, side-walk collision, etc.)?"
            )}
          </p>
        </section>
        <div className="yes-no-buttons">
          <IonButton
            id="immobilized-btn-back"
            className="button-tcs"
            expand="block"
            onClick={() => handleAnswer("Yes")}
          >
            <IonLabel>{t("Yes")}</IonLabel>
          </IonButton>
          <IonButton
            id="immobilized-btn-back"
            className="button-tcs"
            expand="block"
            onClick={() => handleAnswer("No")}
          >
            <IonLabel>{t("No")}</IonLabel>
          </IonButton>
        </div>
      </MainHeader>
    </IonContent>
  );
};

export default WheelsAndTiresIncidentInsurance;
