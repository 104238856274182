import { IonIcon, IonInput, IonLabel } from "@ionic/react";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./VehicleDetails.scss";

interface VinInputProps {
  selectedCarVinNumber?: string;
  onVinInputHandler: (value: string) => void;
  onInfoButtonClick: () => void;
  vinValue?: string;
}

const VINInput: FC<VinInputProps> = ({
  selectedCarVinNumber,
  onVinInputHandler,
  onInfoButtonClick,
  vinValue,
}) => {
  const { t } = useTranslation();
  const [vinLastLetters, setVinLastLetters] = useState(
    vinValue?.slice(10, vinValue.length)
  );

  useEffect(() => {
    if (vinValue && selectedCarVinNumber) {
      const lastLettersInput = vinValue?.slice(10, vinValue.length);
      setVinLastLetters(lastLettersInput);
    }
  }, [vinValue, selectedCarVinNumber]);

  const handleInputChange = useCallback(
    (e: CustomEvent) => {
      const inputElement = e.target as HTMLInputElement;
      const upperCaseValue = inputElement.value?.toUpperCase();
      const vinRegex = /([^\w]+|\s+)/g;
      const sanitizedValue = upperCaseValue
        ?.replace("Q", "0")
        ?.replace("O", "0")
        ?.replace(vinRegex, "");
      onVinInputHandler(sanitizedValue);
      setVinLastLetters(inputElement.value);
    },
    [onVinInputHandler]
  );
  return (
    <>
      <div className="vin-info-icon">
        <h3 id="vin-input-title">{t("Vehicle identification number")}</h3>
        <div onClick={onInfoButtonClick}>
          <IonIcon
            className="icon-info"
            src={`${process.env.PUBLIC_URL}/assets/images/information-circle.svg`}
          />
        </div>
      </div>
      <div>
        {selectedCarVinNumber ? (
          <div className="vin-text">
            {t(
              "To ensure quick processing of your request, please enter the last 7 digits of your vehicle's VIN."
            )}
          </div>
        ) : (
          <div className="vin-text">
            {t(
              "To ensure quick processing of your request, please enter your vehicle's VIN."
            )}
          </div>
        )}
        <div className="vin-numbers-input-container">
          <div className="vin-numbers-input">
            {selectedCarVinNumber && (
              <div className="vin-label">
                <IonLabel>{selectedCarVinNumber.slice(0, 10)}</IonLabel>
              </div>
            )}
            <div
              className={
                selectedCarVinNumber ? "vin-partial-input" : "vin-total-input"
              }
            >
              <IonInput
                value={selectedCarVinNumber ? vinLastLetters : vinValue}
                onIonChange={handleInputChange}
                maxlength={selectedCarVinNumber ? 7 : 17}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="right-align">
        <small>
          {vinLastLetters?.length || 0}/{selectedCarVinNumber ? 7 : 17}{" "}
          {t("chars.")}
        </small>
      </div>
      {selectedCarVinNumber &&
        vinValue &&
        vinValue.length > 10 &&
        selectedCarVinNumber.slice(0, 11) !== vinValue?.slice(0, 11) && (
          <div className="error right-align">
            <small className="vin-ion-error">
              {t("Please check the number.")}
            </small>
          </div>
        )}
      <div className="vin-warning-info wrapper">
        <IonIcon
          size="large"
          className="vin-warning-icon"
          id="vin-warning-icon"
          src={`${process.env.PUBLIC_URL}/assets/images/ph_warning-diamond-fill.svg`}
        />
        <p className="vin-warning-text">
          {t(
            "Failure to provide a valid VIN may result in extended waiting times due to the additional effort required to verify coverage."
          )}
        </p>
      </div>
    </>
  );
};

export default VINInput;
