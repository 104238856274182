import { GlobalState } from "little-state-machine";

export const initialState: GlobalState = {
  steps: {
    locationFields: {
      refresh: false,
      address: "",
      additionalInformation: "",
      placeName: undefined,
      location: undefined,
      googleResult: undefined,
    },
    vehicleDetails: {
      licensePlateNumber: "",
      brand: "",
      model: "",
      color: "",
      howManyPassengers: 0,
      additionalInformations: "",
      country: "",
      canton: "",
      shortLicencePlate: "",
    },
    personalDetails: {
      firstName: "",
      lastName: "",
      streetAddress: "",
      streetNumber: "",
      country: "",
      personalReference: "",
      phone: "",
      postalCode: "",
      city: "",
      policyHolderFirstName: "",
      policyHolderLastName: "",
      policyHolderCompanyName: "",
      policyHolderType: "",
      salesForceResponse: undefined,
    },
    schedule: {
      date: "",
      startTimeRange: "",
      endTimeRange: "",
      time: "",
    },
    abroadCaseDetail: {
      returnDate: "",
      hasCreditCard: false,
      noReturnDate: false,
    },
    insuranceCompanyInfo: {
      icon: "",
      name: "",
      phone: "",
      email: "",
      url: "",
      phoneAbroad: "",
      emailAbroad: "",
      urlAbroad: "",
    },
  },
  isInEditMode: false,
  locateMeNow: false,
  temporaryLocation: undefined,
  summary: undefined,
  temporaryIncidentSummary: undefined,
  hasSubmittedLocation: false,
  isAbroadCase: false,
  isBCMFallBack: false,
  isFromIVR: false,
  MOFISVehicle: undefined,
  hasRetrievedVehicles: false,
  retrievedVehicles: undefined,
  retrievedVehicle: "",
};
