import {
  StarterIssueDetails,
  StarterIssueIncidentOptions,
} from "../../model/Incident/StarterIssueDetails";
import { Case } from "../../api/models/Case";
import { IncidentUtils } from "../IncidentUtils";
import { Appointment } from "../../model/Appointment";
import { AppointmentUtils } from "../AppointmentUtils";
import { PersonalDetailsUtils } from "../PersonalDetailsUtils";
import { PersonDetail } from "../../model/PersonDetail";
import { ImmobilizationType } from "../../model/ImmobilizationType.enum";
import { HighwayUtils } from "../HighwayUtils";
import { LocationUtils } from "../LocationUtils";

export class StarterIssueUtils {
  public static serialize(
    data: StarterIssueDetails,
    appointment: Appointment | undefined,
    personalDetails: PersonDetail,
    immobilizationType: ImmobilizationType,
    immobilizedOnHighway: boolean | undefined
  ): Partial<Case> {
    return {
      MainCause: "12",
      Comment: this.getCaseComment(
        data,
        personalDetails,
        immobilizedOnHighway,
        immobilizationType
      ),
      Priority: HighwayUtils.serializePriority(
        immobilizationType,
        AppointmentUtils.getPriority(appointment)
      ),
      ItemsUsedList: [
        {
          ...IncidentUtils.getItemType(),
          LastVehicleStatus: {
            PrimaryFault: this.getPrimaryFault(data.breakdownType),
            Comment: this.getTCSDescription(data),
          },
        },
      ],
    };
  }

  public static getSummary(data: StarterIssueDetails): string {
    return data.breakdownType;
  }

  private static getTCSDescription(
    data: StarterIssueDetails
  ): string | undefined {
    return [
      this.getCaseDescription(data.breakdownType),
      data.additionalInformation && data.additionalInformation !== ""
        ? IncidentUtils.formatAdditionalInformation(data.additionalInformation)
        : undefined,
    ]
      .filter(Boolean)
      .join("; ");
  }

  private static getPrimaryFault(breakdownType: string): string {
    switch (breakdownType) {
      case StarterIssueIncidentOptions.STEERING_WHEEL_BLOCKED:
        return "65092";
      case StarterIssueIncidentOptions.LIGHT_LEFT_ON_DOOR_NOT_CLOSED:
        return "65033";
      case StarterIssueIncidentOptions.CLICKING_START_NOISES:
        return "65033";
      default:
        // Represents StarterIssueIncidentOptions.OTHER
        return "65076";
    }
  }

  private static getCaseDescription(breakdownType: string): string | undefined {
    switch (breakdownType) {
      case StarterIssueIncidentOptions.STEERING_WHEEL_BLOCKED:
        return "Callback Pannenart; Lenkrad blockiert";
      case StarterIssueIncidentOptions.LIGHT_LEFT_ON_DOOR_NOT_CLOSED:
        return "Callback Pannenart; Licht vergessen oder Tür nicht geschlossen";
      case StarterIssueIncidentOptions.CLICKING_START_NOISES:
        return "Callback Pannenart; Tac-tac-tac-Geräusch";
      default:
        // Represents StarterIssueIncidentOptions.OTHER
        return "Callback Pannenart; Anderes Anlasserproblem";
    }
  }

  private static getCaseComment(
    data: StarterIssueDetails,
    personalDetails: PersonDetail,
    immobilizedOnHighway: boolean | undefined,
    immobilizationType: ImmobilizationType
  ): string | undefined {
    let comment: string[] = [];
    return [
      LocationUtils.getCaseComment(immobilizedOnHighway, immobilizationType),
      ...comment,
      PersonalDetailsUtils.serializeCaseComment(personalDetails),
      ...PersonalDetailsUtils.serializeCoverageComment(personalDetails),
    ]
      .filter(Boolean)
      .join("; ");
  }
}
