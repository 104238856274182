import "./VehicleDetails.scss";

import {
  IonButton,
  IonContent,
  IonIcon,
  IonInput,
  IonLabel,
  IonLoading,
  IonModal,
  IonTextarea,
  isPlatform,
} from "@ionic/react";
import { FormikProvider, useFormik } from "formik";
import { useHistory } from "react-router";
import { useStateMachine } from "little-state-machine";
import {
  updateSteps,
  updateMOFISVehicle,
  updateHasRetrievedVehicles,
  updateRetrievedVehicles,
  updateRetrievedVehicle,
  forceUpdateSteps,
} from "../../state/updateState";
import { RoutePath } from "../../model/RoutePath.enum";
import { MainFooter, MainHeader } from "../Layout/Layout";
import { FormValidation } from "../../helpers/FormValidation";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import classNames from "classnames/bind";
import CarModels from "../../assets/utils/car-model.json";
import { useTranslation } from "react-i18next";
import {
  Brand,
  Color,
  Model,
  Values,
  VehicleDetail,
  VehicleDetailForm,
} from "../../model/VehicleDetail";
import SearchModal from "../shared/SearchModal/SearchModal";
import { CountryUtils } from "../../helpers/CountryUtils";
import { GeneralUtils } from "../../helpers/general-utils";
import { IncidentType } from "../../model/IncidentType.enum";
import { DoorsAndKeysDetails } from "../../model/Incident/DoorsAndKeysDetails";
import {
  FluidLossDetails,
  FluidLossFluidType,
} from "../../model/Incident/FluidLossDetails";
import { ScrollToError } from "../shared/ScrollToError/scroll-to-error";
import carColors from "../../assets/utils/car-color";
import carBrands from "../../assets/utils/car-brand";
import Loading from "../Loading/Loading";
import { Mofis } from "../../api/mofis";
import {
  VehicleBrand,
  VehicleColor,
  VehicleInfos,
} from "../../api/models/VehicleInfos";
import VehicleList from "./VehicleList";
import { VehicleDetailsUtils } from "../../helpers/VehicleDetailsUtils";
import axios from "axios";
import { Country } from "../../model/Country";
import countries from "../../assets/utils/emergency-country-codes";
import { Canton } from "../../model/Canton";
import cantons from "../../assets/utils/canton-codes";
import { forEach } from "@react-google-maps/api/dist/utils/foreach";
import VINInput from "./VINInput";
import VinModal from "./VinModal";

enum ActiveModal {
  brand,
  model,
  color,
}

const VehicleDetails: React.FC = () => {
  const DEFAULT_COUNTRY = "Switzerland";
  const LIECHTENSTEIN = "Liechtenstein";
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { actions, state } = useStateMachine({
    updateSteps,
    updateMOFISVehicle,
    updateHasRetrievedVehicles,
    updateRetrievedVehicles,
    updateRetrievedVehicle,
    forceUpdateSteps,
  });
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showFilterCountryModal, setShowFilterCountryModal] = useState(false);
  const [showFilterCantonModal, setShowFilterCantonModal] = useState(false);
  const [brand, setBrand] = useState<string | null>(null);
  const [brandKey, setBrandKey] = useState<string>("");
  const [activeModal, setActiveModal] = useState<ActiveModal | null>();
  const [model, setModel] = useState<string>("");
  const [color, setColor] = useState<string>("");
  const [isSwissVehicle, setIsSwissVehicle] = useState<boolean>(false);
  const [country, setCountry] = useState<string>();
  const [canton, setCanton] = useState<string>();
  const mainCountry = countries.find((ec) => ec.country_en === DEFAULT_COUNTRY);
  type CountryKey = "country_en" | "country_fr" | "country_de" | "country_it";
  const countryKey: CountryKey = `country_${i18n.language}` as CountryKey;
  const cantonKey: string = `canton_${i18n.language}`;
  const [hasRetrievedVehicles, setHasRetrievedVehicles] = useState<boolean>(
    state.hasRetrievedVehicles
  );
  const [displayVehicleDetails, setDisplayVehicleDetails] = useState<boolean>();
  const [retrievedVehicles, setRetrievedVehicles] = useState<
    VehicleInfos[] | undefined
  >(state.retrievedVehicles);
  const [MOFISChosenVehicle, setMOFISChosenVehicle] = useState<VehicleInfos>();
  const [brandList, setBrandList] = useState<string[]>([]);
  const [colorList, setColorList] = useState<string[]>([]);
  const [selectedVehicle, setSelectedVehicle] = useState<
    number | string | undefined
  >(state.retrievedVehicle);
  const isAbroadCase = state.isAbroadCase;
  const pageRef = useRef<HTMLElement>(null);
  const numberPlateRef = useRef<HTMLIonInputElement>(null);
  const howManyPassengers = useRef<HTMLIonInputElement>(null);
  const [selectedVehicleVIN, setSelectedVehicleVIN] = useState("");
  const [showVinInfoModal, setShowVinInfoModal] = useState(false);
  const [lastRequestId, setLastRequestId] = useState<NodeJS.Timeout | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const requestId = useRef<any>(null);

  const countryCallback = useCallback((value: string) => {
    formik.setFieldValue("country", value);
    setCountry(value);
    setHasRetrievedVehicles(false);
    setRetrievedVehicles(undefined);
    // after a new country is selected and we have a single available brand, set it instead of clearing brand input
    const singleAvailableBrand: string | null = getSingleAvailableBrand();
    setBrand(singleAvailableBrand);
    formik.values.brand = singleAvailableBrand || "";
    setModel("");
    formik.values.model = "";
    setColor("");
    formik.values.color = "";
    formik.values.shortLicencePlate = "";
    formik.setFieldValue("licensePlateNumber", "");
    const numberPlate = numberPlateRef.current;
    if (numberPlate) {
      numberPlate.value = "";
    }

    const foundCountry = CountryUtils.findCountryByFullName(value || "");
    const isLIE = foundCountry?.country_en === LIECHTENSTEIN;
    const isSWZ = foundCountry?.country_en === DEFAULT_COUNTRY;
    if (isLIE) {
      formik.setFieldValue("canton", "FL");
      setCanton("FL");
    } else if (isSWZ) {
      setCanton("");
      formik.setFieldValue("canton", "");
    }
  }, []);

  const cantonCallback = async (value: string) => {
    const previousCanton = canton;
    const pickedCanton = value.slice(0, 2);
    formik.setFieldValue("canton", pickedCanton);
    setCanton(pickedCanton);
    setIsSwissVehicle(true);
    const numberPlate = numberPlateRef?.current?.value;

    if (numberPlate && previousCanton !== pickedCanton) {
      await formik.validateForm();
      retrieveVehicleDetails(pickedCanton + numberPlate, true);
    }
  };

  const getCountriesOptions = (): string[] => {
    const priorityCountries: Record<CountryKey, string[]> = {
      country_en: [
        "Switzerland",
        "France",
        "Germany",
        "Italy",
        "Austria",
        "Liechtenstein",
      ],
      country_fr: [
        "Suisse",
        "France",
        "Allemagne",
        "Italie",
        "Autriche",
        "Liechtenstein",
      ],
      country_de: [
        "Schweiz",
        "Deutschland",
        "Frankreich",
        "Italien",
        "Österreich",
        "Liechtenstein",
      ],
      country_it: [
        "Svizzera",
        "Italia",
        "Francia",
        "Germania",
        "Austria",
        "Liechtenstein",
      ],
    };

    const countryList = countries.map((obj: Country) => obj[countryKey]);

    const priorityCountryList = priorityCountries[countryKey];
    const otherCountries = countryList.filter(
      (country) => !priorityCountryList.includes(country)
    );

    // Sort remaining countries alphabetically
    const sortedOtherCountries = otherCountries.sort((a, b) =>
      a.localeCompare(b)
    );

    return priorityCountryList.concat(sortedOtherCountries);
  };

  const getCantonsOptions = (): string[] => {
    return cantons.map(
      (obj: Canton) =>
        obj["abbreviation" as keyof Canton] +
        " (" +
        obj[cantonKey as keyof Canton] +
        ")"
    );
  };

  useEffect(() => {
    if (state.summary.vehicleDetails.canton) {
      setCanton(state.summary.vehicleDetails.canton);
      if (state.retrievedVehicles) {
        setHasRetrievedVehicles(true);
        setRetrievedVehicles(state.retrievedVehicles);
      }
      if (state.retrievedVehicle) {
        setSelectedVehicle(state.retrievedVehicle);
        shouldDisplayVehicleDetails(state.retrievedVehicle);
      }
    }
  }, []);

  useEffect(() => {
    const foundCountry = CountryUtils.findCountryByFullName(
      country ? country : ""
    );
    const swissVehicle =
      foundCountry?.country_en === DEFAULT_COUNTRY ||
      foundCountry?.country_en === LIECHTENSTEIN;
    setIsSwissVehicle(swissVehicle);
    setDisplayVehicleDetails(
      (swissVehicle && (brand != null || model != "" || color != "")) ||
        (swissVehicle && selectedVehicle === "other")
    );
    if (!swissVehicle) {
      const numberPlate = numberPlateRef.current;
      if (numberPlate) {
        numberPlate.value = "";
      }
    }
    formik.setFieldValue("country", country);
  }, [country]);

  useEffect(() => {
    let isCurrentlySwissPicked = false;
    if ((formik.values.country ?? "") === "" && mainCountry) {
      setCountry(mainCountry[countryKey as keyof Country]);
      isCurrentlySwissPicked =
        mainCountry?.country_en === DEFAULT_COUNTRY ||
        mainCountry?.country_en === LIECHTENSTEIN;
    } else {
      const foundCountry = CountryUtils.findCountryByFullName(
        formik.values.country
      );
      setCountry(foundCountry![countryKey as keyof Country]);
      isCurrentlySwissPicked =
        foundCountry?.country_en === DEFAULT_COUNTRY ||
        foundCountry?.country_en === LIECHTENSTEIN;
    }

    if (formik.values.brand) {
      const currentBrand = carBrands.find((c) =>
        Object.values(c.values).includes(formik.values.brand)
      );
      setBrand(currentBrand!.values[i18n.language as keyof Values]);
      setDisplayVehicleDetails(isCurrentlySwissPicked);
    } else {
      setBrand(null);
    }

    if (formik.values.model) {
      const currentModel = (CarModels as Model[]).find((c) =>
        Object.values(c.values).includes(formik.values.model)
      );
      setModel(currentModel!.values[i18n.language as keyof Values]);
      setDisplayVehicleDetails(isCurrentlySwissPicked);
    } else {
      setModel("");
    }

    if (formik.values.color) {
      const currentColor = carColors.find((c) =>
        Object.values(c.values).includes(formik.values.color)
      );
      setColor(currentColor!.values[i18n.language as keyof Values]);
      setDisplayVehicleDetails(isCurrentlySwissPicked);
    } else {
      setColor("");
    }
  }, [i18n.language]);

  const getSingleAvailableBrand = () => {
    const carBrandsOptions = getBrandOptions();
    return carBrandsOptions?.length === 1 ? carBrandsOptions[0] : null;
  };

  // prefill the brand input if only 1 brand is available
  const prefillBrandIfSingleOption = () => {
    const singleAvailableBrand = getSingleAvailableBrand();
    if (singleAvailableBrand) {
      brandCallback(singleAvailableBrand);
    }
  };

  const shouldDisplayVehicleDetails = (selectedVehicle: any) => {
    setSelectedVehicle(selectedVehicle);
    delete formik.errors.requiredVehicle;
    const index = retrievedVehicles?.findIndex(
      (vehicle) => vehicle.matriculeNumber === selectedVehicle
    );
    setSelectedVehicleVIN("");
    if (
      selectedVehicle !== "other" &&
      selectedVehicle != -1 &&
      retrievedVehicles &&
      index != undefined &&
      retrievedVehicles[index]
    ) {
      const vehicle = retrievedVehicles[index];
      actions.updateMOFISVehicle(vehicle);
      vehicle.vehicleIdentificationNumber &&
        setSelectedVehicleVIN(vehicle.vehicleIdentificationNumber);
    } else {
      actions.updateMOFISVehicle(undefined);
      // prefill single available brand if "other vehicle" is selected after filling number plate
      if (selectedVehicle === "other") {
        prefillBrandIfSingleOption();
      }
    }
    setDisplayVehicleDetails(selectedVehicle === "other");
  };

  useEffect(() => {
    formik.setFieldValue("brand", brand);
    formik.setFieldValue("model", "");
    const currentBrand = carBrands.find((c) =>
      Object.values(c.values).includes(brand)
    );
    if (currentBrand) {
      setBrandKey(currentBrand.key);
    }
  }, [brand]);

  useEffect(() => {
    formik.setFieldValue("model", model);
  }, [model]);

  useEffect(() => {
    formik.setFieldValue("color", color);
  }, [color]);

  useEffect(() => {
    if (isPlatform("desktop")) {
      setTimeout(() => numberPlateRef?.current?.setFocus(), 150);
    }
  }, []);

  useEffect(() => {
    if (formik.values.licensePlateNumber) {
      retrieveVehicleDetails(formik.values.licensePlateNumber);
    }
  }, []);

  const requiredMessage = t("License plate number is a required field");
  const maxLength = t("License plate must be less than or equal to 14");
  const requiredPassengersCount = t("Number of passengers is required");
  const requiredVehicleOption = t("Please choose an option");
  const requiredCanton = t("Please enter the canton to continue.");

  const formik = useFormik({
    initialValues: state.steps.vehicleDetails,
    onSubmit: (vehicleDetails: VehicleDetailForm) => {
      if (displayVehicleDetails || !isSwissVehicle) {
        actions.updateMOFISVehicle(undefined);
      } else {
        vehicleDetails.brand = "";
        vehicleDetails.color = "";
        vehicleDetails.model = "";
      }
      if (!isSwissVehicle || selectedVehicle === "") {
        actions.updateHasRetrievedVehicles(false);
        actions.updateRetrievedVehicles(undefined);
        actions.updateRetrievedVehicle(undefined);
      } else {
        actions.updateHasRetrievedVehicles(true);
        actions.updateRetrievedVehicles(retrievedVehicles);
        actions.updateRetrievedVehicle(selectedVehicle);
      }

      actions.updateSteps({ vehicleDetails });

      actions.forceUpdateSteps({ vehicleDetails });
      goToNextRoute();
    },
    validate: (values: VehicleDetailForm) => {
      const errors: any = {};

      if (isLoading) {
        (errors as any).shortLicencePlate = " ";
      }

      if (!isSwissVehicle) {
        if (!values.licensePlateNumber) {
          (errors as any).licensePlateNumber = requiredMessage;
        }

        values.licensePlateNumber = values.licensePlateNumber
          .toUpperCase()
          .replace(/[^A-Z0-9]+/g, "");

        const match = values.licensePlateNumber.match(/[^A-Za-z0-9//s]/g);

        const matches = match && match.length ? match.length : 0;

        const result = values.licensePlateNumber.length - matches + matches * 2;

        if (result >= 15) {
          (errors as any).licensePlateNumber = maxLength;
        }
      } else {
        // For swiss vehicules
        const cantonRegex = /^[A-Z]{2}/;
        const shortLicencePlateRegex = /^[A-Z]{0,2}\d{0,8}$/;
        const invalidPlateMessage = t(
          "Your input is not a valid Swiss numberplate."
        );
        const noCantonSelectedMessage = "Please select a canton to continue";

        if (!values.canton) {
          (errors as any).canton = noCantonSelectedMessage;
        } else {
          if (cantonRegex.test(values.shortLicencePlate)) {
            const cantonCode = values.shortLicencePlate.slice(0, 2);
            const cantonOptions = getCantonsOptions().map((canton) =>
              canton.slice(0, 2)
            );
            if (
              cantonOptions.includes(
                cantonCode
              ) /* && cantonCode !== 'CC' && cantonCode !== 'CD' && cantonCode !== 'AT'*/
            ) {
              if (
                values.canton !== "CC" &&
                values.canton !== "CD" &&
                values.canton !== "AT"
              ) {
                // check if there's diplomatic vehicle canton to remove
                // when switching from diplomatic to non-diplomatic vehicle
                if (values.canton !== cantonCode) {
                  formik.setFieldValue("canton", cantonCode);
                  setCanton(cantonCode);
                  if (cantonCode === "FL") {
                    formik.setFieldValue("country", LIECHTENSTEIN);
                    setCountry(LIECHTENSTEIN);
                  }
                }
                values.shortLicencePlate = values.shortLicencePlate.slice(2);
                formik.setFieldValue(
                  "shortLicencePlate",
                  values.shortLicencePlate
                );
              }
            } else {
              (errors as any).shortLicencePlate = invalidPlateMessage;
            }
          } else if (!values.shortLicencePlate) {
            (errors as any).shortLicencePlate = requiredMessage;
          } else if (!shortLicencePlateRegex.test(values.shortLicencePlate)) {
            (errors as any).shortLicencePlate = invalidPlateMessage;
          } else if (!values.canton && values.shortLicencePlate) {
            errors.canton = requiredCanton;
          } else if (
            values.canton === "CC" ||
            values.canton === "CD" ||
            values.canton === "AT"
          ) {
            (errors as any).shortLicencePlate = invalidPlateMessage;
          } else if (
            retrievedVehicles &&
            retrievedVehicles.length > 0 &&
            !selectedVehicle
          ) {
            (errors as any).requiredVehicle = requiredVehicleOption;
          } else if (
            process.env.REACT_APP_MOFIS_INTEGRATION === "true" &&
            !selectedVehicle &&
            retrievedVehicles
          ) {
            (errors as any).requiredVehicle = requiredVehicleOption;
          }
        }
      }

      if (
        isAbroadCase &&
        (!values.howManyPassengers || values.howManyPassengers === 0)
      ) {
        (errors as any).howManyPassengers = requiredPassengersCount;
      }

      return errors;
    },
  });

  const retrieveVehicleDetails = (
    plateNumber: string | null | undefined,
    swissVehicle?: boolean
  ) => {
    if (isSwissVehicle || swissVehicle) {
      // Cancel the previous request
      if (lastRequestId !== null) {
        clearTimeout(lastRequestId);
      }
      if (plateNumber!.length > 1) {
        const tId = setTimeout(() => {
          // Cancel the previous API call if it exists
          retrieveVehicleDetailsFromAPI(plateNumber);
        }, 600);
        requestId.current = tId;
        setLastRequestId(tId);
      } else {
        setDisplayVehicleDetails(false);
        setHasRetrievedVehicles(false);
        setRetrievedVehicles(undefined);
      }
    }
  };

  const retrieveVehicleDetailsFromAPI = (
    plateNumber: string | null | undefined
  ) => {
    const iniLastRequestId = requestId.current;
    if (state.token?.access_token) {
      setIsLoading(true);
      Mofis.getVehicleInfos(plateNumber!, state.token.access_token)
        .then((resp) => {
          // In case a call was already made the request ids are different, and we don't need the older request data
          if (iniLastRequestId !== requestId.current) {
            return;
          }
          setIsLoading(false);
          if (resp.data.length > 0) {
            let vehicles = resp.data;
            vehicles = vehicles.filter(
              (car) =>
                car.licensePlateColour === 0 ||
                car.licensePlateColour === 1 ||
                car.licensePlateColour === 2
            );
            const brands = process.env.REACT_APP_CAR_BRANDS_TO_LIST;
            if (brands) {
              const brandsArray = brands.split(",");
              vehicles = resp.data.filter((car) =>
                brandsArray.some(
                  (brand) =>
                    car.brandName.toUpperCase().includes(brand.toUpperCase()) ||
                    brand.toUpperCase().includes(car.brandName.toUpperCase())
                )
              );
            }
            if (vehicles.length > 0) {
              setHasRetrievedVehicles(true);
              setRetrievedVehicles(vehicles);
              setDisplayVehicleDetails(false);
            } else {
              setHasRetrievedVehicles(false);
              setRetrievedVehicles(undefined);
              setDisplayVehicleDetails(true);
              // If no cars from results && only one brand then set it automatically
              const carBrandsOptions = getBrandOptions();
              if (carBrandsOptions.length === 1)
                brandCallback(carBrandsOptions[0]);
            }
            /*const preselectedVehicle = resp.data.findIndex(v => v?.matriculeNumber === state?.MOFISVehicle?.matriculeNumber)
              setDisplayVehicleDetails(preselectedVehicle !== -1);
              setSelectedVehicle(preselectedVehicle);*/
          } else {
            setHasRetrievedVehicles(false);
            setRetrievedVehicles(undefined);
            // prefill single available brand if revealing details after no vehicle is retrieved
            prefillBrandIfSingleOption();
            setDisplayVehicleDetails(true);
          }
        })
        .catch((thrown) => {
          setIsLoading(false);
          // prefill single available brand if revealing details after an error from retrieving vehicle details
          prefillBrandIfSingleOption();
          setDisplayVehicleDetails(true);
          setHasRetrievedVehicles(false);
        });
    } else {
      // prefill single available brand if revealing details from no vehicle API access token
      prefillBrandIfSingleOption();
      setDisplayVehicleDetails(true);
      setHasRetrievedVehicles(false);
    }
  };

  const goToNextRoute = () => {
    if (state.isInEditMode) {
      history.push(`${RoutePath.REQUEST}/${RoutePath.SUMMARY}`);
    } else {
      const isLockedInDoorAndKeys =
        state.steps.incidentType === IncidentType.DOOR_AND_KEYS &&
        (state.steps.incidentDetails as DoorsAndKeysDetails).isLocked;

      const hasSmellInFluidLoss =
        state.steps.incidentType === IncidentType.FLUID_LOSS &&
        (state.steps.incidentDetails as FluidLossDetails).fluidType ===
          FluidLossFluidType.FUEL &&
        (state.steps.incidentDetails as FluidLossDetails).hasSmell;

      if (
        isLockedInDoorAndKeys ||
        hasSmellInFluidLoss ||
        state.steps.immobilizedOnHighway
      ) {
        history.push(`${RoutePath.REQUEST}/${RoutePath.SUMMARY}`);
      } else if (
        CountryUtils.isInSwitzerlandOrLiechtenstein(
          state.steps.locationFields.googleResult
        )
      ) {
        history.push(`${RoutePath.REQUEST}/${RoutePath.SCHEDULE_OPTIONS}`);
      } else {
        history.push(`${RoutePath.REQUEST}/${RoutePath.SUMMARY}`);
      }
    }
  };

  const getBrandOptions = (): string[] => {
    return carBrands
      .filter((b: Brand) => b.additional && b.additional !== "315003")
      .filter((brand) => {
        if (process.env.REACT_APP_CAR_BRANDS_TO_EXCLUDE) {
          const toExclude =
            process.env.REACT_APP_CAR_BRANDS_TO_EXCLUDE.split(",");
          return !toExclude.some(
            (brandToExclude) =>
              brandToExclude.toUpperCase() === brand.values.en.toUpperCase()
          );
        }
        return true;
      })
      .filter((brand) => {
        if (process.env.REACT_APP_CAR_BRANDS_TO_LIST) {
          const brands = process.env.REACT_APP_CAR_BRANDS_TO_LIST.split(",");
          return brands.some(
            (listedBrand) =>
              listedBrand
                .toUpperCase()
                .includes(brand.values.en.toUpperCase()) ||
              brand.values.en.toUpperCase().includes(listedBrand.toUpperCase())
          );
        }
        return true;
      })
      .map((b: Brand) => b.values[i18n.language as keyof Values])
      .sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1));
  };

  const getModelOptions = (): string[] => {
    return (CarModels as Model[])
      .filter((m: Model) => m.parentCode && m.parentCode === brandKey)
      .map((m: Model) => m.values[i18n.language as keyof Values])
      .sort((a, b) => GeneralUtils.orderArrayWithNumbersLast(a, b));
  };

  const brandCallback = useCallback((value: string) => {
    setBrand(value);
    setModel("");
  }, []);

  const modelCallback = useCallback((value: string) => {
    setModel(value);
  }, []);

  const colorCallback = useCallback((value: string) => {
    setColor(value);
  }, []);

  const getColorOptions = (): string[] => {
    return carColors
      .map((obj) => obj.values[i18n.language as keyof Values])
      .sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1));
  };

  const goToPreviousPage = (vehicleDetails: VehicleDetail) => {
    actions.updateRetrievedVehicles(undefined);
    actions.updateRetrievedVehicle(undefined);
    actions.updateHasRetrievedVehicles(false);
    vehicleDetails.shortLicencePlate = "";
    vehicleDetails.canton = "";
    vehicleDetails.licensePlateNumber = "";
    vehicleDetails.color = "";
    vehicleDetails.model = "";
    vehicleDetails.brand = "";
    actions.updateSteps({ vehicleDetails });
    history.back();
  };

  const displayModal = (modal: ActiveModal) => {
    setShowFilterModal(true);
    setActiveModal(modal);
  };

  const sanitizeLicensePlate = (
    license: string,
    isSwzOrLiech: boolean,
    canton: string | undefined
  ) => {
    const alphaNumericRegex = /[^A-Za-z0-9]+/g; // allow alphanumeric characters
    const numericRegex = /[^0-9]+/g; // allow only numbers

    const diplomaticPlates = ["CD", "CC", "AT"];
    const isDiplomaticVehicle = canton && diplomaticPlates.includes(canton);

    // only allow alphanumeric for diplomatic vehicles or non swiss/liech vehicles
    if (isDiplomaticVehicle || !isSwzOrLiech) {
      return license.replace(alphaNumericRegex, ""); // remove non-alphanumeric characters
    } else {
      // only allow numbers for non-diplomatic swiss/liech vehicles
      return license.replace(numericRegex, ""); // remove non-numeric characters
    }
  };

  const handleLicencePlateChange = async (event: CustomEvent) => {
    const foundCountry = CountryUtils.findCountryByFullName(
      country ? country : ""
    );
    const isCurrentlySwissPicked = foundCountry?.country_en === DEFAULT_COUNTRY;
    const isCurrentlyLiechtensteinPicked =
      foundCountry?.country_en === LIECHTENSTEIN;
    const isSwzOrLiech =
      isCurrentlySwissPicked || isCurrentlyLiechtensteinPicked;

    const inputElement = event.target as HTMLInputElement;
    const upperCaseValue = inputElement.value.toUpperCase();
    const sanitizedValue = sanitizeLicensePlate(
      upperCaseValue,
      isSwzOrLiech,
      canton
    );

    if (isSwzOrLiech && !canton) {
      // Swiss and liech plates must always have a canton
      formik.values.shortLicencePlate = "";
      formik.setFieldValue("licensePlateNumber", "");
      return;
    }

    formik.setFieldValue(inputElement.id, sanitizedValue);
    formik.setFieldValue("vin", "");
    setSelectedVehicle("");
    setSelectedVehicleVIN("");

    if (
      isSwzOrLiech &&
      canton &&
      sanitizedValue &&
      sanitizedValue !== formik.values.shortLicencePlate
    ) {
      retrieveVehicleDetails(canton + sanitizedValue);
    } else if (!isCurrentlySwissPicked) {
      retrieveVehicleDetails(sanitizedValue);
    }
  };

  const handleVinNumberChange = (vinInput: string) => {
    formik.setFieldTouched("vin");
    if (selectedVehicleVIN) {
      formik.validateField("vin");
      const vin = `${selectedVehicleVIN.slice(0, 10)}${vinInput}`;
      formik.setFieldValue("vin", vin);
    } else {
      formik.setFieldValue("vin", vinInput);
    }
  };

  return (
    <IonContent>
      <FormikProvider value={formik}>
        <ScrollToError />
        <form onSubmit={formik.handleSubmit}>
          <MainHeader title={t("Please tell us about your vehicle")}>
            <div className="vehicle-details-container">
              <section>
                <h6>{t("Country")}</h6>
                <IonButton
                  id="pd-country"
                  className="button-tcs input"
                  expand="block"
                  onClick={() => setShowFilterCountryModal(true)}
                >
                  <div className="wrapper">
                    <IonLabel>{country}</IonLabel>
                    <IonIcon
                      slot="end"
                      src={`${process.env.PUBLIC_URL}/assets/images/chevron-down-outline.svg`}
                    />
                  </div>
                </IonButton>
                {FormValidation.getFormErrorMessage("country", formik, t)}
              </section>
              {!isSwissVehicle && (
                <>
                  <section>
                    <h6>{t("License plate number")}</h6>
                    <IonInput
                      ref={numberPlateRef}
                      className={classNames({
                        "tcs-invalid": FormValidation.getFormErrorMessage(
                          "licensePlateNumber",
                          formik,
                          t
                        ),
                      })}
                      id="licensePlateNumber"
                      placeholder={t(
                        "placeholder_license_plate_vehicle_details"
                      )}
                      maxlength={10}
                      name="licensePlateNumber"
                      value={formik.values.licensePlateNumber}
                      onIonChange={handleLicencePlateChange}
                    />
                    {FormValidation.getFormErrorMessage(
                      "licensePlateNumber",
                      formik,
                      t
                    )}
                  </section>
                  <h2 className="details-title">{`${t(
                    "Details(optional)"
                  )} (${t("optional")})`}</h2>
                  <section>
                    <h6>{t("Brand")}</h6>
                    <IonButton
                      id="vd-brand"
                      className="button-tcs input"
                      expand="block"
                      onClick={() => displayModal(ActiveModal.brand)}
                    >
                      <div className="wrapper">
                        <IonLabel>{brand}</IonLabel>
                        <IonIcon
                          slot="end"
                          src={`${process.env.PUBLIC_URL}/assets/images/chevron-down-outline.svg`}
                        />
                      </div>
                    </IonButton>
                  </section>

                  <section>
                    <h6>{t("Model")}</h6>
                    <IonButton
                      id="vd-model"
                      className="button-tcs input"
                      expand="block"
                      onClick={() => displayModal(ActiveModal.model)}
                    >
                      <div className="wrapper">
                        <IonLabel>{model}</IonLabel>
                        <IonIcon
                          slot="end"
                          src={`${process.env.PUBLIC_URL}/assets/images/chevron-down-outline.svg`}
                        />
                      </div>
                    </IonButton>
                  </section>
                  <section>
                    <h6>{t("Color")}</h6>
                    <IonButton
                      id="vd-color"
                      className="button-tcs input"
                      expand="block"
                      onClick={() => displayModal(ActiveModal.color)}
                    >
                      <div className="wrapper">
                        <IonLabel>{color}</IonLabel>
                        <IonIcon
                          slot="end"
                          src={`${process.env.PUBLIC_URL}/assets/images/chevron-down-outline.svg`}
                        />
                      </div>
                    </IonButton>
                  </section>
                </>
              )}
              {isSwissVehicle && (
                <section>
                  <h6>{t("License plate number")}</h6>
                  <div className={"splittedLicencePlateContainer"}>
                    <div>
                      <IonButton
                        id="pd-canton"
                        className={classNames(
                          {
                            "tcs-invalid": FormValidation.getFormErrorMessage(
                              "canton",
                              formik,
                              t
                            ),
                          },
                          "button-tcs input smallSelect"
                        )}
                        expand="block"
                        onClick={() => setShowFilterCantonModal(true)}
                        // Liechtenstein country must always have "FL" canton
                        disabled={country === LIECHTENSTEIN && canton === "FL"}
                      >
                        <div className="wrapper">
                          <IonLabel>{canton}</IonLabel>
                          <IonIcon
                            slot="end"
                            src={`${process.env.PUBLIC_URL}/assets/images/chevron-down-outline.svg`}
                          />
                        </div>
                      </IonButton>
                    </div>
                    <IonInput
                      ref={numberPlateRef}
                      className={classNames({
                        "tcs-invalid": FormValidation.getFormErrorMessage(
                          "shortLicencePlate",
                          formik,
                          t
                        ),
                        "generic-disabled": !canton,
                      })}
                      id="shortLicencePlate"
                      placeholder={t(
                        "placeholder_license_plate_vehicle_details"
                      )}
                      maxlength={10}
                      name="shortLicencePlate"
                      value={formik.values.shortLicencePlate}
                      onIonChange={handleLicencePlateChange}
                      disabled={!canton}
                    />
                  </div>
                  {FormValidation.getFormErrorMessage("canton", formik, t)}
                  {FormValidation.getFormErrorMessage(
                    "shortLicencePlate",
                    formik,
                    t
                  )}
                  {isLoading ? (
                    <div className="loader-vehicles">
                      <img
                        width={50}
                        height={50}
                        src={`${process.env.PUBLIC_URL}/assets/images/loader.svg`}
                        alt=""
                      />
                    </div>
                  ) : (
                    hasRetrievedVehicles && (
                      <>
                        <VehicleList
                          vehicles={retrievedVehicles}
                          selectedVehicle={shouldDisplayVehicleDetails}
                          pickedVehicle={selectedVehicle}
                        />
                        {formik.errors?.requiredVehicle && (
                          <small className="ion-error">
                            {t(formik.errors.requiredVehicle)}
                          </small>
                        )}
                      </>
                    )
                  )}
                </section>
              )}
              {!isLoading &&
                process.env.REACT_APP_ENABLE_VIN_INPUT === "true" &&
                (selectedVehicle ||
                  displayVehicleDetails ||
                  state.steps.vehicleDetails.vin) && (
                  <section className="vin-input">
                    <VINInput
                      onInfoButtonClick={() => setShowVinInfoModal(true)}
                      onVinInputHandler={handleVinNumberChange}
                      vinValue={formik.values.vin}
                      selectedCarVinNumber={selectedVehicleVIN}
                    />
                  </section>
                )}
              <IonModal
                presentingElement={pageRef.current!}
                isOpen={showVinInfoModal}
                onDidDismiss={() => setShowVinInfoModal(false)}
                id="vin-modal"
              >
                <VinModal onClose={() => setShowVinInfoModal(false)} />
              </IonModal>
              {(!process.env.REACT_APP_MOFIS_INTEGRATION ||
                process.env.REAC_APP_MOFIS_INTEGRATION == "false" ||
                displayVehicleDetails) &&
                !isLoading && (
                  <>
                    <h2 className="details-title">{`${t(
                      "Details(optional)"
                    )} (${t("optional")})`}</h2>
                    <section>
                      <h6>{t("Brand")}</h6>
                      <IonButton
                        id="vd-brand"
                        className="button-tcs input"
                        expand="block"
                        onClick={() => displayModal(ActiveModal.brand)}
                      >
                        <div className="wrapper">
                          <IonLabel>{brand}</IonLabel>
                          <IonIcon
                            slot="end"
                            src={`${process.env.PUBLIC_URL}/assets/images/chevron-down-outline.svg`}
                          />
                        </div>
                      </IonButton>
                    </section>
                    <section>
                      <h6>{t("Model")}</h6>
                      <IonButton
                        id="vd-model"
                        className="button-tcs input"
                        expand="block"
                        onClick={() => displayModal(ActiveModal.model)}
                      >
                        <div className="wrapper">
                          <IonLabel>{model}</IonLabel>
                          <IonIcon
                            slot="end"
                            src={`${process.env.PUBLIC_URL}/assets/images/chevron-down-outline.svg`}
                          />
                        </div>
                      </IonButton>
                    </section>
                    <section>
                      <h6>{t("Color")}</h6>
                      <IonButton
                        id="vd-color"
                        className="button-tcs input"
                        expand="block"
                        onClick={() => displayModal(ActiveModal.color)}
                      >
                        <div className="wrapper">
                          <IonLabel>{color}</IonLabel>
                          <IonIcon
                            slot="end"
                            src={`${process.env.PUBLIC_URL}/assets/images/chevron-down-outline.svg`}
                          />
                        </div>
                      </IonButton>
                    </section>
                  </>
                )}
              {isAbroadCase && (
                <>
                  <section>
                    <h6>{t("lbl_passenger_question")}</h6>
                    <IonInput
                      ref={howManyPassengers}
                      className={classNames({
                        "tcs-invalid": FormValidation.getFormErrorMessage(
                          "howManyPassengers",
                          formik,
                          t
                        ),
                      })}
                      id="howManyPassengers"
                      min="0"
                      max="99"
                      maxlength={2}
                      pattern="[0-9]+"
                      type="number"
                      inputmode="numeric"
                      name="howManyPassengers"
                      value={formik.values.howManyPassengers}
                      onIonChange={formik.handleChange}
                    />
                    {FormValidation.getFormErrorMessage(
                      "howManyPassengers",
                      formik,
                      t
                    )}
                  </section>
                  <section>
                    <h6>{t("Additional information")}</h6>
                    <IonTextarea
                      rows={5}
                      maxlength={250}
                      id="additionalInformations"
                      name="additionalInformations"
                      value={formik.values.additionalInformations}
                      onIonChange={formik.handleChange}
                      placeholder={t("txb_add_info_placeholder")}
                    />
                    {FormValidation.getFormErrorMessage(
                      "additionalInformations",
                      formik,
                      t
                    )}
                  </section>
                </>
              )}
            </div>
          </MainHeader>
          <MainFooter>
            {!state.isInEditMode && (
              <IonButton
                id="vd-back"
                className="button-tcs back"
                expand="block"
                onClick={() => goToPreviousPage(formik.values)}
              >
                <div className="wrapper">
                  <IonIcon
                    size="large"
                    src={`${process.env.PUBLIC_URL}/assets/images/chevron-back.svg`}
                  />
                  <IonLabel>{t("Back")}</IonLabel>
                  <div />
                </div>
              </IonButton>
            )}
            {state.isInEditMode && (
              <IonButton
                id="vd-cancel"
                className="button-tcs"
                expand="block"
                onClick={() => history.back()}
              >
                <IonLabel>{t("Cancel")}</IonLabel>
              </IonButton>
            )}
            {!state.isInEditMode && (
              <IonButton
                id="vd-next"
                className="button-tcs cta"
                color="primary"
                expand="block"
                onClick={() => formik.handleSubmit()}
              >
                <div className="wrapper">
                  <div />
                  <IonLabel>{t("Next")}</IonLabel>
                  <IonIcon
                    slot="end"
                    size="large"
                    src={`${process.env.PUBLIC_URL}/assets/images/chevron-forward.svg`}
                  />
                </div>
              </IonButton>
            )}
            {state.isInEditMode && (
              <IonButton
                id="vd-save"
                className="button-tcs cta"
                color="primary"
                expand="block"
                onClick={() => formik.handleSubmit()}
              >
                <IonLabel>{t("Save")}</IonLabel>
              </IonButton>
            )}
          </MainFooter>
        </form>
      </FormikProvider>
      <IonModal
        isOpen={showFilterModal}
        onDidDismiss={() => setShowFilterModal(false)}
        swipeToClose={true}
        presentingElement={pageRef.current!}
      >
        {(function () {
          switch (activeModal) {
            case ActiveModal.brand:
              return (
                <SearchModal
                  title={t("Brands")}
                  onDismissModal={() => setShowFilterModal(false)}
                  elements={getBrandOptions()}
                  selectedValue={brandCallback}
                />
              );
            case ActiveModal.model:
              return (
                <SearchModal
                  title={t("Models")}
                  onDismissModal={() => setShowFilterModal(false)}
                  elements={getModelOptions()}
                  selectedValue={modelCallback}
                />
              );
            case ActiveModal.color:
              return (
                <SearchModal
                  title={t("Colors")}
                  onDismissModal={() => setShowFilterModal(false)}
                  elements={getColorOptions()}
                  selectedValue={colorCallback}
                />
              );

            default:
              break;
          }
        })()}
      </IonModal>
      <IonModal
        isOpen={showFilterCountryModal}
        onDidDismiss={() => setShowFilterCountryModal(false)}
        swipeToClose={true}
        presentingElement={pageRef.current!}
      >
        <SearchModal
          title={t("Countries")}
          onDismissModal={() => setShowFilterCountryModal(false)}
          elements={getCountriesOptions()}
          selectedValue={countryCallback}
        />
      </IonModal>
      <IonModal
        isOpen={showFilterCantonModal}
        onDidDismiss={() => setShowFilterCantonModal(false)}
        swipeToClose={true}
        presentingElement={pageRef.current!}
      >
        <SearchModal
          title={t("Cantons")}
          onDismissModal={() => setShowFilterCantonModal(false)}
          elements={getCantonsOptions()}
          selectedValue={cantonCallback}
        />
      </IonModal>
    </IonContent>
  );
};

export default VehicleDetails;
