import "./FluidLossIncident.scss";

import {
  IonButton,
  IonContent,
  IonIcon,
  IonLabel,
  IonTextarea,
  IonRadioGroup,
  IonItem,
  IonRadio,
} from "@ionic/react";
import { useStateMachine } from "little-state-machine";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { FormikProvider, useFormik } from "formik";
import { FormValidation } from "../../../helpers/FormValidation";
import React, { useRef } from "react";
import { updateSteps } from "../../../state/updateState";
import { MainHeader, MainFooter } from "../../Layout/Layout";
import {
  FluidLossDetails,
  FluidLossFluidType,
} from "../../../model/Incident/FluidLossDetails";
import { IncidentType } from "../../../model/IncidentType.enum";
import { useTranslation } from "react-i18next";
import { IncidentUtils } from "../../../helpers/IncidentUtils";
import { ScrollToError } from "../../shared/ScrollToError/scroll-to-error";

const incidentOptions = [
  {
    label: FluidLossFluidType.COOLANT,
    value: FluidLossFluidType.COOLANT,
  },
  {
    label: FluidLossFluidType.OIL,
    value: FluidLossFluidType.OIL,
  },
  {
    label: FluidLossFluidType.FUEL,
    value: FluidLossFluidType.FUEL,
  },
  {
    label: FluidLossFluidType.OTHER_UNKNOWN,
    value: FluidLossFluidType.OTHER_UNKNOWN,
  },
];

const FluentLossIncident: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { actions, state } = useStateMachine({ updateSteps });
  const textArea = useRef<HTMLIonTextareaElement>(null);

  const requiredAdditionalField = t(
    "Additional information is a required field"
  );
  const requiredFluidTypeField = t("Fluid type is a required field");
  const radioStart =
    process.env.REACT_APP_RADIO_START &&
    process.env.REACT_APP_RADIO_START == "true"
      ? "start"
      : "end";

  const validationSchema = Yup.object({
    fluidType: Yup.string().required(requiredFluidTypeField),
    additionalInformation: Yup.string().when("fluidType", {
      is: (fluidType: string) => fluidType === FluidLossFluidType.OTHER_UNKNOWN,
      then: Yup.string().required(requiredAdditionalField),
    }),
  });

  const getInitialValues = (): FluidLossDetails => {
    const initialValues =
      state.steps.incidentDetails &&
      state.steps.incidentType === IncidentType.FLUID_LOSS
        ? state.steps.incidentDetails
        : {
            fluidType: undefined,
            hasSmell: undefined,
            additionalInformation: "",
          };
    return initialValues as FluidLossDetails;
  };

  const formik = useFormik({
    initialValues: getInitialValues(),
    validationSchema,
    onSubmit: (data: FluidLossDetails) => handleSubmit(data),
    validate: () => {
      const shouldScroll =
        formik.values.fluidType && !formik.values.additionalInformation;
      if (shouldScroll && textArea && textArea.current) {
        textArea.current.scrollIntoView();
      }
    },
  });

  const handleSubmit = (fluidLossIncident: FluidLossDetails) => {
    if (
      state.isInEditMode &&
      state.steps.schedule.date &&
      state.steps.schedule.startTimeRange &&
      state.steps.schedule.endTimeRange &&
      state.steps.schedule.time
    ) {
      // reset appointment
      actions.updateSteps({
        schedule: {
          date: "",
          startTimeRange: "",
          endTimeRange: "",
          time: "",
        },
      });
    }
    actions.updateSteps({ incidentDetails: fluidLossIncident });
    IncidentUtils.goToNextRoute(
      state.isInEditMode,
      state.steps.immobilizedOnHighway,
      history,
      state.summary
    );
  };

  return (
    <IonContent>
      <FormikProvider value={formik}>
        <ScrollToError />
        <form className="fluid-loss-component" onSubmit={formik.handleSubmit}>
          <MainHeader
            imageTitle={t("Fluid Loss")}
            image={`${process.env.PUBLIC_URL}/assets/images/${
              process.env.REACT_APP_ALT_ICONS_PATH || ""
            }${IncidentType.FLUID_LOSS}.svg`}
          >
            <section>
              <h3>{t("What type of fluid is your vehicle loosing?")}</h3>
              <IonRadioGroup
                value={formik.values.fluidType}
                name="fluidType" // should have the same name as the form
                onIonChange={(e) => {
                  formik.setFieldValue("fluidType", e.detail.value);
                  formik.setFieldValue("hasSmell", undefined);
                }}
              >
                {incidentOptions.map(({ label, value }, i) => {
                  return (
                    <IonItem key={i}>
                      <span className={"tab capitalize"}>{t(label)}</span>
                      <IonRadio slot={radioStart} value={value} />
                    </IonItem>
                  );
                })}
              </IonRadioGroup>
              {FormValidation.getFormErrorMessage("fluidType", formik, t)}
            </section>
            <section>
              <h6>
                {t("Additional information")}
                {IncidentUtils.displayOptionalTag(
                  state.isInEditMode
                    ? state.summary.incidentType
                    : state.steps.incidentType,
                  formik.values.fluidType,
                  t
                )}
              </h6>
              <IonTextarea
                rows={5}
                ref={textArea}
                id="additionalInformation"
                name="additionalInformation"
                value={formik.values.additionalInformation}
                onIonChange={formik.handleChange}
              />
              {FormValidation.getFormErrorMessage(
                "additionalInformation",
                formik,
                t
              )}
            </section>
          </MainHeader>
          <MainFooter>
            {!state.isInEditMode && (
              <IonButton
                id="fluid-loss-back"
                className="button-tcs back"
                expand="block"
                onClick={() => history.back()}
              >
                <div className="wrapper">
                  <IonIcon
                    size="large"
                    src={`${process.env.PUBLIC_URL}/assets/images/chevron-back.svg`}
                  />
                  <IonLabel>{t("Back")}</IonLabel>
                  <div />
                </div>
              </IonButton>
            )}
            {state.isInEditMode && (
              <IonButton
                id="fluid-loss-cancel"
                className="button-tcs"
                expand="block"
                onClick={() => history.back()}
              >
                <IonLabel>{t("Cancel")}</IonLabel>
              </IonButton>
            )}
            {!state.isInEditMode && (
              <IonButton
                id="fluid-loss-next"
                className="button-tcs cta"
                color="primary"
                expand="block"
                onClick={() => formik.handleSubmit()}
              >
                <div className="wrapper">
                  <div />
                  <IonLabel>{t("Next")}</IonLabel>
                  <IonIcon
                    slot="end"
                    size="large"
                    src={`${process.env.PUBLIC_URL}/assets/images/chevron-forward.svg`}
                  />
                </div>
              </IonButton>
            )}
            {state.isInEditMode && (
              <IonButton
                id="fluid-loss-save"
                className="button-tcs cta"
                color="primary"
                expand="block"
                onClick={() => formik.handleSubmit()}
              >
                <IonLabel>{t("Save")}</IonLabel>
              </IonButton>
            )}
          </MainFooter>
        </form>
      </FormikProvider>
    </IonContent>
  );
};

export default FluentLossIncident;
